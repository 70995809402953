import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["id", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("input", {
      class: _normalizeClass(["checkbox", {'is-active': $setup.isChecked, 'is-disabled': $props.disabled}]),
      id: $setup.props.id,
      type: "checkbox",
      disabled: $setup.isDisabled || $props.loading,
      onInput: _cache[0] || (_cache[0] = ($event: any) => ($setup.updateValue(!$setup.isChecked)))
    }, null, 42, _hoisted_1),
    ($setup.props.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass(["checkbox-label", {disabled: $setup.isDisabled || $props.loading}]),
          onClick: $setup.handleLabelClick
        }, _toDisplayString($props.label), 3))
      : _createCommentVNode("", true)
  ], 64))
}