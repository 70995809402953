import { UserState } from "@busy-human/hxp-library";
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";
import { useAuth } from "./auth";

export const useUserstate = defineStore('userstate', () => {
    const _state = ref<UserState.Model>(UserState.Default());
    const isReady = ref<boolean>(false);
    const LS_KEY = 'hxp.userstate';
    const auth = useAuth();

    if(localStorage.getItem(LS_KEY)) {
        try {
            _state.value = JSON.parse(localStorage.getItem(LS_KEY)!);
        } catch (e) {
            console.error(e);
            localStorage.removeItem(LS_KEY);
        }
    }

    async function loadRemote() {
        const id = auth.isSubUser ? auth.subUserUID : auth.currentUID;
        console.log("Userstate from ", id);
        if(id) {
            const doc = UserState.Collection.docEntity(id);
            const data = await doc.fetch();
            delete (data as any).$id;
            _state.value = data;
        } else {
            _state.value = UserState.Default();
        }
    }

    watch(() => auth.currentUID, async uid => {
        await loadRemote();
        
        // Little check for the fun of it
        // if(uid && auth.claims?.builderId && _state.value.ownerBuilder && auth.claims.builderId !== _state.value.ownerBuilder) {
        //     console.error(`User ${uid} builderId mismatch: token=${auth.claims['builderId']}, userState=${_state.value.ownerBuilder}`);
        // }
    
        isReady.value = true;
        waitingResolves.forEach(func => func());
    }, { immediate: true });

    const state = computed(() => _state.value);

    async function updateState(newState: Partial<UserState.Model>) {
        console.log("Set called");
        _state.value = {..._state.value, ...newState};
        localStorage.setItem(LS_KEY, JSON.stringify(_state.value));
        const id = auth.isSubUser ? auth.subUserUID : auth.currentUID;
        if(id) {
            await UserState.Collection.docEntity(id).set(_state.value);
        }
    }

    const waitingResolves: (() => void)[] = [];

    const waitForReady = () => {
        if(isReady.value) return;
        else {
            return new Promise<void>(res => {waitingResolves.push(res);});
        }
    };

    const hasGoneToRegistration = computed(() => 
        state.value.stage === UserState.BuilderStage.REGISTRATION ||
        state.value.stage === UserState.BuilderStage.BUILDER
    );

    return { state, waitForReady, updateState, hasGoneToRegistration };

});