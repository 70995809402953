import { type Ref, onBeforeUnmount, onMounted } from "vue";


export default function useClickOutside (targetRef: Ref<HTMLElement | null>, callback: () => void){
    if ( !targetRef ) return;

    const listener = (e: Event) => {
        if (!targetRef.value || e.target === targetRef.value || e.composedPath().includes(targetRef.value)) return;
        if (typeof callback == 'function') {
            callback();
        }
    };

    onMounted(()=>{
        window.addEventListener('click', listener);
    });
    onBeforeUnmount(() => {
        window.removeEventListener('click', listener);
    });
    return {
        listener
    };
} 