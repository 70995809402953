/* eslint-disable max-lines-per-function */
import { DataInstance } from "@busy-human/gearbox";
import { Invoices, Builders } from "@busy-human/hxp-library";
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";
import { useBuilderStore } from "./builder";
import { useSeasons } from "./seasons";
import { useAuth } from "./auth";

export const useInvoices = defineStore('invoices', () => {
    const builderStore = useBuilderStore();
    const seasons = useSeasons();

    const seasonId = computed(() => seasons.current?.$id || "");

    const invoices = ref(new Map<string, DataInstance<Invoices.Model>>());

    // This might be backwards
    const asArray = computed(() => [...invoices.value.values()]);

    //let filteredUsers = users.filter((user) => {
    //    return user.age > 40 && user.occupation === 'programmer';
    //});

    const buildersInvoices = computed(()=>asArray.value.filter((invoice) => (Builders.IsBuilderAdult(builderStore.currentBuilder as Builders.Model) ? invoice.builderId === builderStore.currentBuilder?.$id : invoice.parentBuilderId === builderStore.currentBuilder?.$id)));

    function getCurrentInvoice(builderId: string){
        const builderInvoice = invoices.value.get(Invoices.getInvoiceId(builderId, seasonId.value)) || null;
        // const builderCurrentInvoices = builderInvoices;
            //.filter((invoice) => invoice.invoiceType === 'Invoice' && invoice.season === seasons.current?.$id);

        // if(builderCurrentInvoices.length <= 0) {
        //     console.log(`Builder ${builderId} somehow doesn't have an invoice`);
        //     return null;
        // }
    
        // if(builderCurrentInvoices.length > 1){
        //     console.warn('Builder somehow has more than 1 invoice');
        // }
    
        return builderInvoice;
    }

    const builderCurrentInvoice = computed(() => (builderStore.currentBuilder ? getCurrentInvoice(builderStore.currentBuilder.$id) : null));


    const loading = ref(false);

    async function refreshData() {
        if(builderStore.asArray.length <= 0) return;
        if(loading.value) return;
        console.log("Refreshing transactions");
        loading.value = true;
        invoices.value.clear();

        await builderStore.waitForReady();
        
        const tmp: DataInstance<Invoices.Model>[] = [];
        await Promise.allSettled(builderStore.asArray.map(async b => {
            const [t1, t2] = await Promise.all([
                Invoices.Collection.subset((_r, query) => query.where('builderId', '==', b.$id).toQuery()).fetch(),
                Invoices.Collection.subset((_r, query) => query.where('parentBuilderId', '==', b.$id).toQuery()).fetch()  
            ]);
            tmp.push(...t1.map(v => v.data()));
            tmp.push(...t2.map(v => v.data()));
        }));

        for(const t of tmp) {
            invoices.value.set(t.$id, t);
        }

        loading.value = false;
    }

    const authStore = useAuth();
    watch(() => builderStore.asArray, async () => {
        await refreshData();
    }, { immediate: true });

    const builderTotalPaid = computed(()=>{
        let paid = 0;
        if(builderCurrentInvoice.value){
            paid += builderCurrentInvoice.value?.totalPaid || 0;
        }
        return paid;
    });

    const builderTotalRemaining = computed(()=>{
        let owed = 0;
        if(builderCurrentInvoice.value){
            owed += builderCurrentInvoice.value?.remainingBalance || 0;
        }
        return owed;
    });

    const builderTotalOwed = computed(()=>{
        let owed = 0;
        if(builderCurrentInvoice.value){
            owed += builderCurrentInvoice.value?.totalOwed || 0;
        }
        return owed;
    });
    
    const totalPaid = computed(()=>{
        let paid = 0;
        builderStore.asArray.forEach((builder)=>{
            const builderId = builder.$id;
            const record = builderStore.recordByBuilderId(builderId);
            if(record.status !== 'canceled'){
                const invoice = getCurrentInvoice(builderId);
                if(invoice){
                    paid += invoice.totalPaid;
                }
            }
        });
        return paid;
    });

    const totalRemaining = computed(()=>{
        let owed = 0;
        builderStore.asArray.forEach((builder)=>{
            const builderId = builder.$id;
            const record = builderStore.recordByBuilderId(builderId);
            if(record.status !== 'canceled'){
                const invoice = getCurrentInvoice(builderId);
                if(invoice){
                    owed += invoice.remainingBalance < 0 ? 0 : invoice.remainingBalance;
                }
            }
        });
        return owed;
    });

    return { invoices, asArray, refreshData, buildersInvoices, builderCurrentInvoice, getCurrentInvoice, builderTotalPaid, builderTotalOwed, builderTotalRemaining, totalPaid, totalRemaining };
});