/* eslint-disable complexity */
import {
	Destinations,
	Builders,
	DataInstance,
	Trips,
} from "@busy-human/hxp-library";
import type { ISOTimestamp } from "@busy-human/gearbox";
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";
import { useShoppingBuilderStore } from "./builder-shopping";
import { useDestinations } from "./destinations";
import { useTrips } from "./trips";
import { debounce } from "lodash";
import moment from 'moment';
import { analytics  } from "../../src/util/firebase";
import { logEvent } from "firebase/analytics";
import { useBuilderStore } from "./builder";


export type TripBuilderFilters = {
	builderAge?: number;
	builderBirthday?: Date;
	builderGender?: Builders.Gender;
};

export type TripInputFilters = {
	price?: string,
	dates?: string[],
	tripType?: string[]
	vaccineRequired?: boolean;
	availability?: string[]

	region?: Destinations.Region;
};

export type TripFilters = TripBuilderFilters & TripInputFilters

// eslint-disable-next-line max-lines-per-function
export const useFilterStore = defineStore("filter", () => {

	const searchQuery = ref<string>('');

	const builderFilters = computed<TripBuilderFilters>(() => {
		const builderStore = useShoppingBuilderStore();
		const {selectedBuilder} = builderStore;
		const __filter: TripBuilderFilters = {};
		if (selectedBuilder?.birthday) {
			__filter.builderAge = Builders.GetBuilderAge(selectedBuilder.birthday);
			__filter.builderBirthday = new Date(selectedBuilder.birthday);
		}

		if (selectedBuilder?.gender)
			__filter.builderGender = selectedBuilder!.gender;

		return __filter;
	});

	const _filter = ref<TripInputFilters>({});
	const inputFilters = computed<TripInputFilters>(() => {
		const __filter = {
			..._filter.value,
		};

		return __filter;
	});

	const FilterMap: Record<keyof TripFilters, (trip: Trips.Model) => boolean> = {
		'price': () => true,
		'dates': filterDateSlot,
		'tripType': filterServiceProject,
		'vaccineRequired': filterVaccine,
		'availability': filterAvailability,
		'builderAge': filterAge,
		'builderBirthday': () => true,
		'builderGender': () => true,
		'region': filterRegion
	};

	const _tripListView = ref<'table' | 'card'>('card');

	const tripListView = computed(() => _tripListView.value);

	function toggleTripListView(){
		if(_tripListView.value === 'card'){
			_tripListView.value = "table";
		}else if(_tripListView.value === 'table'){
			_tripListView.value = 'card';
		}
	}

	function addFilter<T extends keyof TripInputFilters>(filterName: T, value: any) {
		_filter.value[filterName] = value;
	}

	function removeFilter(filterName: keyof TripInputFilters) {
		delete _filter.value[filterName];
	}

	type filterDestinationOptions = {
		filterByInput?: boolean
		filterByBuilder?: boolean
		filterByAge?: boolean
	}

	function filterDestination(destination: DataInstance<Destinations.Model>, options?: filterDestinationOptions) {
		const builderStore = useShoppingBuilderStore();
		const destinations = useDestinations();
		const trips = destinations.destinationSlots[destination.$id];

		if(options && !options.filterByInput && !options.filterByBuilder && !options.filterByAge) return true;

		if(!options || options.filterByInput){
			if(searchQuery.value && !destination.name.toLowerCase().includes(searchQuery.value.toLowerCase())) return false;
		}

		const tripList: DataInstance<Trips.Model>[] = trips.filter(trip => {
			// filter by groups

			if(!options || options.filterByBuilder){
				if(builderStore.selectedGroup && !filterGroups(trip)) return false;
			}

			if(!options || options.filterByAge){
				if(builderStore.selectedGroup && !filterAge(trip)) return false;
			}

			if(!options || options.filterByInput){
				for(const filterName in inputFilters.value) {
					if(filterName in FilterMap) {
						const filterFunc = FilterMap[filterName as keyof typeof FilterMap];
						// eslint-disable-next-line max-depth
						if(!filterFunc(trip)) {
							return false;
						}
					} else {
						console.warn(`Filter ${filterName} not found!`);
					}
				}
			}

			return true;
		});

		return tripList.length > 0;
	}

	function sortDestinations(destinationList: DataInstance<Destinations.Model>[]){
		return destinationList.sort((a: DataInstance<Destinations.Model>, b: DataInstance<Destinations.Model>) => {

			if(inputFilters.value.price){
				const difference = (a.price) - (b.price);

				if(difference !== 0){
					if(inputFilters.value.price === 'highToLow'){
						return difference * -1;
					}else if(inputFilters.value.price === 'lowToHigh'){
						return difference;
					}
				}
			}

			return a.name.localeCompare(b.name);
		});
	}

	function slotAvailable(trip: DataInstance<Trips.Model>) {
		const builderStore = useShoppingBuilderStore();

		const desiredAvailability = 1;
		let slotsAvailable = 0;
		let teenMaleAvailable = 0;
		let teenFemaleAvailable = 0;
		let parentMaleAvailable = 0;
		let parentFemaleAvailable = 0;

		const groupTypes: Builders.Group[] = [
			'teen_male',
			'teen_female',
			'parent_male',
			'parent_female'
		];

		for(const groupType of groupTypes){
			if (builderStore.builderGroupIsOfGroup(groupType) && !!trip.groups?.[groupType]){
				slotsAvailable +=
					trip.groups?.[groupType].max - trip.groups?.[groupType].registered ||
					0;
			}
		}

		if (slotsAvailable >= desiredAvailability) {
			return true;
		}

		return false;
	}

	//Filter Functions

	function filterDestinationBy(filterName: string, destination: DataInstance<Destinations.Model>) {
		const destinations = useDestinations();
		// const trip = trips.trips[slot.destinationId];

		switch (filterName) {
			case "builderAge": {
				if(!builderFilters.value.builderBirthday) return true;
				const age = Builders.GetBuilderAge(builderFilters.value.builderBirthday.toISOString());
				return age >= Builders.MinimumAgeForAdult || (
					age >= Destinations.GetDestinationMinimumAge(destination) && age < Builders.AdultAge
				);
			}
			case 'region':
				return filterRegion(destinations.destinationSlots[destination.$id]?.[0]);

			case "price":
				// There is no filter for price
				break;

			case "dates":
			   return filterDate(destination);

			case "serviceProject":
				// if (filter.value.tripType == null) return true;
				// return trip.project.type.includes(filter.value.tripType as Destinations.ProjectType)
				break;
			case "vaccineRequired":
				// return filterVaccine(trips.trips[trip.$id]?.[0]);
				break;

			// case "visaRequired":
			// 	if (filter.value.visaRequired == null) return true;
			// 	return (typeof trip.visaRequired === 'string') === filter.value.visaRequired

			case "passportRequired":
			// return filterPassport(trip);
				break;

			default:
				console.log("Provided FilterName not supported");
				return false;
		}
	}

	function filterGroups(trip: DataInstance<Trips.Model>) {
		return !!slotAvailable(trip);
	}

	function filterAge(trip: Trips.Model) {
		const builderStore = useShoppingBuilderStore();
		if(!builderFilters.value.builderBirthday || !builderStore.selectedGroup) return true;
		const destinations = useDestinations();
		const destination = destinations.destinations[trip.destination];
		if(!trip) return false;

		const teenBuilder = builderStore.builders[builderStore.selectedGroup.teen];
		const parentBuilder = builderStore.builders[builderStore.selectedGroup.parent || ''] || null;
		if(teenBuilder && !builderStore.selectedGroup.parent){
			return Builders.IsBuilderAllowedInSlot(teenBuilder as Builders.Model, trip, destination, false);
		}if(teenBuilder && parentBuilder) {
			return Builders.IsBuilderAllowedInSlot(teenBuilder as Builders.Model, trip, destination, false);
		}else if(parentBuilder){
			return Builders.IsBuilderAllowedInSlot(parentBuilder as Builders.Model, trip, destination, false);
		}else{
			return false;
		}
	}

	function filterDate(destination: DataInstance<Destinations.Model>) {
		if (inputFilters.value.dates === undefined) return true;
		const slotsStore = useTrips();
		const trips = slotsStore.asArray.filter((trip)  => trip.destination === destination.$id);

		const hasValidSlot = trips.find((slot) => filterDateSlot(slot));
		return !!hasValidSlot;
	}

	function filterDateSlot(trip: Trips.Model) {
		if (inputFilters.value.dates === undefined) return true;
		const tripStart = new Date(trip.startDate);

		const months = [
			'january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'
		];

		const validForFilter = inputFilters.value.dates.find((month) => {
			const monthIdx = months.indexOf(month.toLowerCase());
			if(monthIdx >= 0){
				const startOfMonth = new Date(tripStart.getFullYear(), monthIdx, 1);
				const endOfMonth = new Date(tripStart.getFullYear(), monthIdx+1, 0);
				if (tripStart <= endOfMonth && tripStart >= startOfMonth) {
					return true;
				}
			}
			return false;
		});

		return !!validForFilter;
	}

	function filterServiceProject(trip: Trips.Model) {
		if (inputFilters.value.tripType === undefined) return true;
		const destinations = useDestinations();
		const destination = destinations.destinations[trip.destination];
		console.log("Trip Type LOGAN!!", inputFilters.value.tripType);
		const destinationTripType = (destination.destinationType?.split(" ")?.[1]) || "";
		console.log("Destination Trip Type", destinationTripType);
		if(inputFilters.value.tripType.includes(destinationTripType)) return true;
		return false;
	// 	// return tripStore.trips[trip.destinationId].project.type.includes(filter.value.tripType as Destinations.ProjectType)
	}

	// function filterSightseeing(trip: Trips.Model) {
	// 	const tripStore = useTripsStore();
	// 	if (filter.value.sightseeing == undefined) return true
	// 	return tripStore.trips[trip.destinationId].sightseeing.includes(filter.value.sightseeing)
	// }

	function filterVaccine(trip: Trips.Model) {
		const destinations = useDestinations();
		if (inputFilters.value.vaccineRequired === undefined) return true;
		const {vaccine} = destinations.destinations[trip.destination];
		const vaccineRequired = !(!vaccine || vaccine === 'No vaccines' || vaccine === 'None');
		return vaccineRequired === inputFilters.value.vaccineRequired;
	}

	// function filterVisa(trip: Trips.Model) {
	// 	const tripStore = useTripsStore();
	// 	if (filter.value.visaRequired == null) return true;
	// 	return (typeof tripStore.trips[trip.destinationId].visaRequired === 'string') === filter.value.visaRequired
	// }

	// function filterPassport(trip: Trips.Model) {
	// 	const tripStore = useTripsStore();
	// 	if (filter.value.passportRequired == undefined) return true
	// 	return tripStore.trips[trip.destinationId].passportRequired === filter.value.passportRequired
	// }

	// function filterAdventureLevel(trip: Trips.Model) {
	// 	const tripStore = useTripsStore();
	// 	if (filter.value.adventureLevel === undefined) return true;
	// 	return tripStore.trips[trip.destinationId].adventureLevel === filter.value.adventureLevel;
	// }

	function filterRegion(trip: Trips.Model) {
		const destinations = useDestinations();
		if (inputFilters.value.region === undefined) return true;
		return destinations.destinations[trip.destination].region === inputFilters.value.region;
	}

	// function filterAvailability(trip: Trips.Model) {
	// 	if (inputFilters.value.availability === undefined) return true;
	// 	const builderTypes = useBuilderStore();
	// 	const builderType = builderTypes.builders.value[builder.builderType];
	// 	const builderUserType = (builderType.builderType?.split(" ")?.[1]) || "";
	// 	console.log("Builder User Type", builderUserType);
	// 	if(inputFilters.value.availability.includes(builderUserType)) return true;
	// 	return false;
	// // 	// return tripStore.trips[trip.destinationId].project.type.includes(filter.value.tripType as Destinations.ProjectType)
	// }

	function filterAvailability(trip: Trips.Model) {
		if (inputFilters.value.availability === undefined) return true;

		if(inputFilters.value.availability.includes('Teen Male')) {
			if(trip.groups.teen_male.spots > 0) {
				return true;
			}
		} 
		if (inputFilters.value.availability.includes('Teen Female')) {
			if(trip.groups.teen_female.spots > 0) {
				return true;
			}
		} 
		if (inputFilters.value.availability.includes('Parent Female')) {
			if(trip.groups.parent_female.spots > 0) {
				return true;
			}
		} 
		if (inputFilters.value.availability.includes('Parent Male')) {
			if(trip.groups.parent_male.spots > 0) {
				return true;
			}
		}

		console.log(inputFilters.value.availability);

		return false;
	}

	const searchDebounce = debounce(()=>{
		logEvent(analytics,"search",{
			searchQuery: searchQuery.value
		});
	},2000);

	watch(()=> searchQuery.value, (newVal, oldVal)=>{
		searchDebounce();
	});


	return {
		searchQuery,
		inputFilters,
		builderFilters,

		addFilter,
		removeFilter,

		sortDestinations,
		filterDestination,

		slotAvailable,
		filterDestinationBy,

		tripListView,
		toggleTripListView
	};
});
