import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, withModifiers as _withModifiers, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-card-contents" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createElementVNode("div", {
      class: "modal-scrim",
      onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => ($props.clickToClose && _ctx.$emit('close')), ["stop"]))
    }, [
      _createElementVNode("div", {
        class: "modal-card",
        onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ])
    ])
  ]))
}