/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-use-before-define */
/* eslint-disable prefer-destructuring */
/* eslint-disable max-depth */
/* eslint-disable max-lines-per-function */
import { BuilderRecord, Builders, DataInstance, Season } from "@busy-human/hxp-library";
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";
import { useAuth } from "./auth";
import { useTrips } from "./trips";
import { useSeasons } from "./seasons";
import { DocumentEntity, SubsetEntity, dataInstanceToData } from "@busy-human/gearbox";

type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };

/** ONLY USE THIS IN THE SHOPPING PORTAL */
export const useShoppingBuilderStore = defineStore('shopping-builders', () => {
    type LocalBuilder = WithRequired<Partial<Builders.Model>, 'name'>;

    const auth = useAuth();
    const season = useSeasons();

    const builders = ref<{[id: string]: LocalBuilder}>({});
    const builderRecords = ref<Record<string, BuilderRecord.Model>>({});
    const builderSaved = ref<{[id: string]: boolean}>({});
    const selectedBuilderId = ref<string | null>(null);
    const authstate = ref<'waiting' | 'unauth' | 'ready'>('waiting');
    const LS_KEY = "hxp.builders";
    const LS_KEY2 = "hxp.builderRecords";

    
    type Modify<T, R> = Omit<T, keyof R> & R;
    type BuilderTemplate  = Modify<Builders.Model, {
        age?: number
        gender?: Builders.Gender
    }>
    /**
     * Creates a new builder, does not save any data
     */
    function addBuilder(select = true) {
        if(auth.isSubUser) throw new Error("Cannot create builders as subuser");
        const id = Builders.Collection.makeId();
        const newBuilder: BuilderTemplate = Builders.Default();
        delete newBuilder.age;
        delete newBuilder.gender;
        
        builders.value[id] = newBuilder;
        builderRecords.value[id] = BuilderRecord.Default();
        builderRecords.value[id].season = useSeasons().current?.$id || "";

        if(auth.currentUID) builders.value[id].userId = auth.currentUID;

        if(select) selectedBuilderId.value = id;

        builderSaved.value[id] = false;

        saveLocalStorage();

        return id;
    }

    function setBuilderPair(teenId: string, parentId: string) {
        builderRecords.value[teenId].builderTypeDetails = {
            goingAsPair: true,
            goingOnTrip: true,
            pairUser: parentId
        };
        builderRecords.value[parentId].builderTypeDetails = {
            goingAsPair: true,
            goingOnTrip: true,
            pairUser: teenId
        };
        builderRecords.value[parentId].builderType = Builders.BuilderType.ParentBuilder;
    }

    function setMaybeParent(teenId: string, value = true) {
        builderRecords.value[teenId].builderTypeDetails.maybePair = value;
    }

    /**
     * Writes a builder to the database
     * @param id id of the builder locally
    */
    // eslint-disable-next-line complexity
    async function saveBuilder(id?: string) {
        if(!id) id = selectedBuilderId.value || undefined;
        if(!id) throw new Error("Must select an id");

        if(!builders.value[id]) throw new Error("Builder id does not exist");
        if(!builders.value[id].birthday) throw new Error("Builder missing birthday");
        if(!builders.value[id].gender) throw new Error("Builder missing gender");
        if(!builders.value[id].name) throw new Error("Builder missing name");

        const buildRef = Builders.ModelSchema.parse(builders.value[id]);
        const recordRef = {...BuilderRecord.Default(), ...builderRecords.value[id]};

        if(!recordRef.season) recordRef.season = useSeasons().current?.$id || "";
            
        let builderType: Builders.BuilderType = Builders.BuilderType.Builder;
        if(Builders.IsBuilderAdult(buildRef)) {
            // if(recordRef.builderTypeDetails.goingOnTrip) {
                builderType = Builders.BuilderType.ParentBuilder;
            // } else {
            //     builderType = Builders.BuilderType.AtHomeParent;
            // }
        }
        builders.value[id].builderType = builderType;
        buildRef.builderType = builderType;

        if(auth.currentUID){
            if(!buildRef.userId) buildRef.userId = auth.currentUID;
            if(!builders.value[id].userId) builders.value[id].userId = auth.currentUID;

            const docRef = Builders.Collection.docEntity(id);
            const recRef = Season.Collection.subCollectionEntity(season.current?.$id || "", "BuilderRecord").docEntity(id);

            try {
                if(isBuilderSaved(id)) {
                    await Promise.all([docRef.update(buildRef), recRef.update(recordRef)]);
                } else {
                    await Promise.all([docRef.set(buildRef), recRef.set(recordRef)]);
                }
            } catch (e) {
                console.error(e);
                throw new Error("Error while saving builder");
            }
            builderSaved.value[id] = true;
        }else{
            console.warn('User not logged in, Builder will not be saved to database until login');
        }
    }

    async function deleteBuilder(id?: string) {
        if(!id) id = selectedBuilderId.value || undefined;
        if(!id) throw new Error("Must select an id");
        if(auth.isSubUser) throw new Error("Cannot delete builders as sub user");
        
        if(auth.currentUID) {
            builderRecordEntities.value[id].cleanup();
            delete builderRecordEntities.value[id];
            try {
                await Season.Collection.subCollectionEntity(season.current?.$id || "", "BuilderRecord").deleteDoc(id);
                await Builders.Collection.deleteDoc(id);
            } catch (e) {
                console.error(e);
            }
        }
        delete builders.value[id];
        delete builderRecords.value[id];
        
        if(id === selectedBuilderId.value) {
            selectedBuilderId.value = Object.keys(builders.value)[0];
        }

        //Remove References
        const linkedBuilders = Object.keys(builders.value).map(id2 => ({... builderRecords.value[id2],$id: id2})).filter((builder)=> builder.builderTypeDetails?.pairUser === id);
        await Promise.all(linkedBuilders.map(async (builder) => {
            builder.builderTypeDetails!.goingAsPair = false;
            builder.builderTypeDetails!.pairUser = null;
            await saveBuilder(builder.$id);
        }));
    }

    function isBuilderSaved(id: string) {
        return !!builderSaved.value[id];
    }

    const selectedBuilder = computed<LocalBuilder | null>(() => (selectedBuilderId.value ? builders.value[selectedBuilderId.value] : null));
    const selectedBuilderRecord = computed(() => (selectedBuilderId.value ? builderRecords.value[selectedBuilderId.value] : null));

    function selectBuilder(id: string) {
        selectedBuilderId.value = id;
    }

    const asArray = computed<DataInstance<LocalBuilder>[]>(() => Object.keys(builders.value).map(id => ({
            ... builders.value[id],
            $id: id
        })));

    const adultArray = computed<DataInstance<LocalBuilder>[]>(() => asArray.value.filter(b => Builders.IsBuilderAdult(b as Builders.Model)));

    function readLocalStorage() {
        const tmp = localStorage.getItem(LS_KEY);
        const tmp2 = localStorage.getItem(LS_KEY2);
        if(tmp && tmp2) {
            const JSONBuilders = JSON.parse(tmp) as typeof builders.value;
            const JSONRecords = JSON.parse(tmp2) as typeof builderRecords.value; 
            
            builders.value = JSONBuilders;
            builderRecords.value = JSONRecords;

            //Remove invalid trip slots
            const trips = useTrips();
            trips.waitForReady().then(() => {
                let updatedPreferences = false;
                for(const id in builderRecords.value) {
                    if(builderRecords.value[id].preferences && builderRecords.value[id].preferenceDetail){
                        const filteredList = builderRecords.value[id].preferences.filter((pref) => trips.trips[pref]);
                        if(filteredList.length !== builderRecords.value[id].preferences.length){
                            builderRecords.value[id].preferences = filteredList;
                            const newDeets: BuilderRecord.Model['preferenceDetail'] = {};
                            for(const b of filteredList) {
                                newDeets[b] = builderRecords.value[id].preferenceDetail[b];
                            }
                            builderRecords.value[id].preferenceDetail = newDeets;
                            updatedPreferences = true;
                        }
                    }
                }
                if(updatedPreferences) {
                    saveLocalStorage();
                    console.log("NEW VALUE", builders.value);
                    console.log("NEW RECORDS", builderRecords.value);
                }
            });
        }
    }
    function saveLocalStorage() {
        localStorage.setItem(LS_KEY, JSON.stringify(builders.value));
        localStorage.setItem(LS_KEY2, JSON.stringify(builderRecords.value));
    }
    function clearLocalStorage() {
        localStorage.removeItem(LS_KEY);
        localStorage.removeItem(LS_KEY2);
    }

    watch(builders, () => {
        saveLocalStorage();
    }, { deep: true });

    readLocalStorage();
    if(Object.keys(builders.value).length !== 0) {
        selectedBuilderId.value = Object.keys(builders.value)[0];
    }

    const builderSubset = ref<null | SubsetEntity<Builders.Model>>(null);
    const builderRecordEntities = ref<Record<string, DocumentEntity<BuilderRecord.Model>>>({});
    function setupListeners(uid: string) {
        builderSubset.value = Builders.Collection.getBuildersForUserSubset(uid);
        builderSubset.value.onUpdate(() => {
            if(!builderSubset.value) return;
            for(const b of builderSubset.value.dataItems()) {
                if(!b.isDeleted) {
                    builders.value[b.$id] = dataInstanceToData(b);
                } else if(b.$id in builders.value) {
                    delete builders.value[b.$id];
                }
                const seasonId = season.current?.$id;
                if(!seasonId) return;
                if(!(b.$id in builderRecordEntities.value)) {
                    const ent = Season.Collection.subCollectionEntity(seasonId, "BuilderRecord")
                        .docEntity(b.$id);
                    ent.onUpdate(() => {
                        builderRecords.value[ent.$id] = ent.data();
                    });
                    ent.listen();
                    builderRecordEntities.value[ent.$id] = ent;
                }
            }
            console.log("Listener Update");
        });
        builderSubset.value.listen();
    }
    function cleanupListeners() {
        for(const ent of Object.values(builderRecordEntities.value)) {
            ent.cleanup();
        }
        builderRecordEntities.value = {};
        builderSubset.value?.cleanup();
        builderSubset.value = null;
    }

    watch(() => auth.currentUID, async uid => {
        if(uid) {
            const existing = await Builders.Collection.getBuildersForUser(uid);
            const existingRecords = await Promise.all(existing.map(e => 
                Season.Collection.subCollectionEntity(season.current?.$id || "", "BuilderRecord").fetchData(e.$id)
            ));
            if(authstate.value === 'unauth') {
                // User logged in
                const tmp: typeof builders.value = {};
                const tmp2: typeof builderRecords.value = {};

                for(const remoteBuilder of existing) {
                    if(!remoteBuilder.isDeleted) {
                        tmp[remoteBuilder.$id] = dataInstanceToData(remoteBuilder);
                        builderSaved.value[remoteBuilder.$id] = true;
                    }
                }

                for(const remoteRecord of existingRecords) {
                    tmp2[remoteRecord.$id] = dataInstanceToData(remoteRecord);
                }

                // merge
                builders.value = {
                    ...builders.value,
                    ...tmp
                };

                builderRecords.value = {
                    ...builderRecords.value,
                    ...tmp2
                };

                Object.keys(builders.value).forEach((builderId) => {
                    if(!isBuilderSaved(builderId)){
                        saveBuilder(builderId);
                    }
                });
            } else {
                // User was already logged in
                const tmp: typeof builders.value = {};
                const tmp2: typeof builderRecords.value = {};
                for(const remoteBuilder of existing) {
                    if(!remoteBuilder.isDeleted) {
                        tmp[remoteBuilder.$id] = dataInstanceToData(remoteBuilder);
                        builderSaved.value[remoteBuilder.$id] = true;
                    }
                }
                for(const remoteBuilder of existingRecords) {
                    tmp2[remoteBuilder.$id] = dataInstanceToData(remoteBuilder);
                }
                builders.value = tmp;
                builderRecords.value = tmp2;
            }
            setupListeners(uid);

            if(auth.subUserBuilderId) selectedBuilderId.value = auth.subUserBuilderId;
            else selectedBuilderId.value = Object.keys(builders.value)[0];

            authstate.value = 'ready';
        } else {
            builders.value = {};
            builderRecords.value = {};
            cleanupListeners();
            if(authstate.value === 'ready') {
                // User was logged in, now logged out
                clearLocalStorage();
                selectedBuilderId.value = null;
            } else {
                // User is not logged in
                readLocalStorage();
            }
            authstate.value = 'unauth';
        }
    }, { immediate: true });

    function builderIsOfGroup(group: Builders.Group) {
        if(!selectedBuilder.value) return true;
        if(!selectedBuilder.value.birthday) return true;
        if(!selectedBuilder.value.gender) return true;

        return (Builders.GetBuilderGroup(selectedBuilder.value as Builders.Model) === group);
    }


    // Builder Groupings
    type builderGroup = {
        teen?: string
        parent?: string
    }

    function validateTeenBirthday(birthday: string | undefined){
        if(!birthday) return 'invalid';


        const test = new Date(birthday).getTime();
        if(isNaN(test)) return 'invalid';


        const builderAge = Builders.GetBuilderAge(birthday);

        if(builderAge >= Builders.AdultAge ) return 'tooOld';
        if(builderAge < (Builders.MinimumAge-1) ) return 'tooYoung';

	    return 'valid';
    }

    const selectableGroups = computed<builderGroup[]>(() => {
        const builderGroups: builderGroup[] = [];
        asArray.value.filter((builder) => builder.birthday).forEach((builder) => {
            const validate = validateTeenBirthday(builder.birthday);
            const details = builderRecords.value[builder.$id];
            if(validate === 'valid'){
                const builderGroup: builderGroup = {teen: builder.$id};
                
                if(details?.builderTypeDetails?.goingAsPair && details?.builderTypeDetails?.pairUser && builders.value?.[details.builderTypeDetails.pairUser]){
                    builderGroup.parent = details.builderTypeDetails.pairUser;
                }
                builderGroups.push(builderGroup);
            }else if(validate === 'tooOld' && details && !details?.builderTypeDetails?.pairUser){
                const builderGroup: builderGroup = {parent: builder.$id};
                builderGroups.push(builderGroup);
            }
        });
        return builderGroups;
    });

    const selectedGroup = computed<builderGroup | null>(() => {
        if(selectableGroups.value.length <= 0) return null;
        let selected = selectableGroups.value.find((group) => selectedBuilderId.value === group.teen || (selectedBuilderId.value && selectedBuilderId.value === group.parent));
        if(!selected){
            const builderId = selectableGroups.value[0]?.teen || selectableGroups.value[0]?.parent;
            if(builderId){
                selected = selectableGroups.value[0];
                selectBuilder(builderId);
            }
        }
        return selected || null;
    });

    function builderGroupIsOfGroup(group: Builders.Group, builderGroup?: builderGroup) {
        const currentGroup = builderGroup || selectedGroup.value;
        if(!currentGroup) return false;
        if(currentGroup.parent) return (Builders.GetBuilderGroup(builders.value[currentGroup.parent] as Builders.Model) === group);
        if(currentGroup.teen) return (Builders.GetBuilderGroup(builders.value[currentGroup.teen] as Builders.Model) === group);
        return false;
    }

    function getGroupBuilderGroup(builderGroup?: builderGroup) {
        const currentGroup = builderGroup || selectedGroup.value;
        if(!currentGroup) return null;
        if(currentGroup.parent) return Builders.GetBuilderGroup(builders.value[currentGroup.parent] as Builders.Model);
        if(currentGroup.teen) return Builders.GetBuilderGroup(builders.value[currentGroup.teen] as Builders.Model);
    }

    return { builders, builderRecords, addBuilder, setBuilderPair, setMaybeParent, selectedBuilder, selectedBuilderRecord, selectBuilder, asArray, selectedBuilderId, saveBuilder, isBuilderSaved, deleteBuilder, builderIsOfGroup, adultArray, selectableGroups, selectedGroup, builderGroupIsOfGroup, getGroupBuilderGroup, validateTeenBirthday };
});