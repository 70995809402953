import { createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { id: "start-registration" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "registration-confirm" }
const _hoisted_4 = { class: "checkbox-container" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["disabled"]
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.REGISTRATION_PORTAL_LIVE)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", {
            class: "text",
            innerHTML: $setup.registrationOpenHtml
          }, null, 8, _hoisted_2),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode($setup["Checkbox"], {
                modelValue: $setup.termsAccepted,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.termsAccepted) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", {
              class: "text",
              onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.termsAccepted = !$setup.termsAccepted)),
              innerHTML: $setup.registrationOpenConfirmHtml
            }, null, 8, _hoisted_5)
          ]),
          _createElementVNode("button", {
            class: "progress g-small-subheading",
            disabled: !$setup.termsAccepted,
            onClick: $setup.goToRegistration
          }, "Continue", 8, _hoisted_6)
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", {
            class: "text",
            innerHTML: $setup.registrationClosedHtml
          }, null, 8, _hoisted_7),
          _createElementVNode("button", {
            class: "progress g-small-subheading",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
          }, "Okay")
        ], 64))
  ]))
}