import {ref, computed, watch,onMounted} from 'vue';
import { events, EventTypes } from "@/events";

const MOBILE_SIZE = 1050;

export const isMobile  = ref(false);
export const collapsed = ref(false);
export const toggleCollapse = (forceTo?: boolean) =>{
    collapsed.value = (forceTo !== undefined ? forceTo : !collapsed.value);
    if(!collapsed.value){
        console.log("Emit close agenda");
        events.emit(EventTypes.closeAgenda);
    }
};

events.on(EventTypes.closeNav,()=>{
    console.log("On Close Nav");
    if(!collapsed.value){
        collapsed.value = true; 
    }
});

function checkNavState(){
    const windowWidth = window.innerWidth;
    if(windowWidth > MOBILE_SIZE){
        isMobile.value = false;
        if(collapsed.value){
            collapsed.value = false;
        }
    }else{
        if(!collapsed.value){
            collapsed.value = true;
        }
        isMobile.value = true;
    }
}

watch(()=> window.innerWidth, () =>{
    checkNavState();

},{immediate: true});

window.addEventListener("resize",()=>{
    checkNavState();
});


export const NAVBAR_WIDTH = 375;
export const MOBILE_WIDTH = 300;
//export const NAVBAR_WIDTH_COLLAPSED = 100;


export const navbarWidth = computed(()=>`${isMobile.value ? MOBILE_WIDTH : NAVBAR_WIDTH}px`);