import { createApp } from "vue";
import AppComponent from "./document/app.vue";
import { initRaygun, setTags } from "./util/raygun-util";
import UnderConstruction from "./components/under-construction.vue";

const app = createApp(AppComponent);
app.component('under-construction', UnderConstruction);

const raygunKey = CONFIG.raygun.key;
initRaygun(raygunKey, { Vue: app, version: VERSION, environment: ENVIRONMENT });
setTags([`platform:${PLATFORM}`, `environment:${ENVIRONMENT}`]);

export const APP_ROOT_EL = "#application-root";

export {app};
export default app;
