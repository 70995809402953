import { DataInstance, ISOTimestamp } from '@busy-human/gearbox';
import { Passports, NotarizedDocs, MedicalInfo, Lottery, Builders, DonationLinks } from '@busy-human/hxp-library';
import { httpsCallable } from 'firebase/functions';
import { functions } from './firebase';

interface SubmitCartArgs {
    builderId: string
    seasonId: string
};
export const submitCart = httpsCallable<SubmitCartArgs, void>(functions, "submitCart");

interface InitiateDepositArgs {
    builderIds: string[],
    redirectUrl: string,
    seasonId: string,
    variableDeposit?: boolean
}
export const initiateDeposit = httpsCallable<InitiateDepositArgs, {paymentLink: string}>(functions, "initiateDeposit");


interface InitiateCustomPaymentArgs {
    builderIds: string[],
    redirectUrl: string,
    seasonId: string,
    amount?: number
}
export const initiateCustomPayment = httpsCallable<InitiateCustomPaymentArgs, {paymentLink: string}>(functions, "initiateCustomPayment");


interface InitiateDonationArgs {
    builderIds: string[],
    redirectUrl: string,
    seasonId: string,
    amount?: number
}
export const initiateDonation = httpsCallable<InitiateDonationArgs, {paymentLink: string}>(functions, "initiateDonation");

interface GetDepositStatusesArgs {
    builderIds: string[]
    seasonId: string
}

export interface BuilderDepositStatus {
    builderId: string,
    depositAmountDue: number,
    isDepositPaid: boolean,
    lastDepositAttempt: string | null
}

export const getDepositStatuses = httpsCallable<GetDepositStatusesArgs, {statuses: BuilderDepositStatus[]}>(functions, "getDepositStatuses");

export const adminGetInfo = httpsCallable<{email: string}, {uid: string, builderId: string | undefined, rootUID: string | undefined}>(functions, 'adminGetInfo');

interface CreateLotteryArgs {
    seasonId?: string,
    groups?: Lottery.Group[],
    maxBuilders: number | "Infinity"
}
export const createLottery = httpsCallable<CreateLotteryArgs, string>(functions, "createLottery", {timeout: 540000});

interface ExecuteLotteryArgs {
    lotteryId: string
}
export const executeLottery = httpsCallable<ExecuteLotteryArgs, void>(functions, "executeLottery", {timeout: 540000});

interface ExecuteLotteryBatchesArgs {
    lotteryId: string,
    batchSize?: number
    minWaitlistCounter?: number
}

export const executeLotteryBatches = httpsCallable<ExecuteLotteryBatchesArgs, void>(functions, "executeLotteryBatches", {timeout: 540000});

interface ChangeTripArgs {
    builderId: string,
    tripId: string,
    seasonId?: string
}
export const changeTrip = httpsCallable<ChangeTripArgs, boolean>(functions, "changeTrip");

interface CancelTripArgs {
    builderId: string
    seasonId?: string
    unlinkPair?: boolean
}
export const cancelTrip = httpsCallable<CancelTripArgs, void>(functions, "cancelTrip");

interface WaitlistArgs {
    builderId: string,
    seasonId?: string
}

export const waitlistBuilder = httpsCallable<WaitlistArgs, void>(functions, "waitlistBuilder");

type getRootUserInfoArgs = void | {
    uid?: string
}
type getRootUserInfoResult = {
    email: string
}
export const getRootUserInfo = httpsCallable<getRootUserInfoArgs, getRootUserInfoResult>(functions, "getRootUserInfo");

type createSubUserArgs = {
    userId?: string
    builderId: string
    email: string
    password: string
};
export const createSubUser = httpsCallable<createSubUserArgs, void>(functions, "createSubUser");

type adminCreateBuilderArgs = {
    userDetails: {
        email: string
        password?: string
        uid?: string
    }
    builderDetails: Pick<Builders.Model, "name" | "birthday" | "gender" | "builderType">
    seasonId?: string
}
export const adminCreateBuilder = httpsCallable<adminCreateBuilderArgs, {builderId: string, log: string[]}>(functions, "adminCreateBuilder");

export const sendDoctorPDF = httpsCallable<{builderId: string, pdfURL: string}, true>(functions, 'sendDoctorPDF');

export const updateMedicalInfo = httpsCallable<{builderId: string, physicalInfo: MedicalInfo.Physical.Information, mentalInfo: MedicalInfo.Mental.Information, medicationInfo: MedicalInfo.Medications.Information}, string>(functions, 'updateMedicalInfo');

export const createDonationLink = httpsCallable<{builderId: string, seasonId: string }, string>(functions, 'createDonationLink');

export const getDonationLink = httpsCallable<{donationLinkId: string}, DonationLinks.Model>(functions, 'getDonationLink');

export const viewStripeReceipt = httpsCallable<{transactionId: string, seasonId?: string}, {url: string}>(functions, 'viewStripeReceipt');

type TrainingDateArgs = {
    seasonId: string
    builderId: string
    date: string
};
export const builderSetTrainingDate = httpsCallable<TrainingDateArgs, true>(functions, "builderSetTrainingDate");

type adminTransactionTransferArgs = {
    fromBuilder: string
    toBuilder: string
    amount: number
}
export const adminTransactionTransfer = httpsCallable<adminTransactionTransferArgs, void>(functions, "adminTransactionTransfer");

export const adminChangePassword = httpsCallable<{email: string, password: string}, boolean>(functions, "adminChangePassword");

// export const getDonationLinkInfo = null;
export const updatePassport = null;
export const requestTransfer = null;
export const requestCancellation = null;
export const updateNotarizedDoc = null;
export const peak15BookBuildersDeposit = null;
export const userChangeBuilderId = null;
export const peak15GetInvoice = null;

// interface BookBuilderArgs {
//     builderIds: string[]
//     ownerId: string
// }
// export const peak15BookBuildersDeposit = httpsCallable<BookBuilderArgs, boolean>(functions, "peak15BookBuildersDeposit");

// export interface InvoiceData {
//     balanceDue: number,
//     total: number,
//     invoiceNumber: string,
//     processed: boolean,
//     status: 'Approved' | 'Disapproved' | 'Submitted',
//     finalPaymentDue: ISOTimestamp
// }


// export const peak15GetInvoice = httpsCallable<{bookingId: string}, DataInstance<InvoiceData>>(functions, 'peak15GetInvoice');



// export const requestCancellation = httpsCallable<{bookingId: string}, true>(functions, 'requestCancellation');

// export const requestTransfer = httpsCallable<{bookingId: string, tripId: string}, true>(functions, 'requestTransfer');


// export type PassportData = Pick<Passports.Model, 'ppExpires' | 'ppIssued' | 'ppNameFirst' | 'ppNameLast' | 'ppNameMiddle' | 'ppNumber'>
// export type NotarizedDocData = NotarizedDocs.Model

// export const updatePassport = httpsCallable<{builderId: string, passport: PassportData}, true>(functions, 'updatePassport');
// export const updateNotarizedDoc = httpsCallable<{builderId: string, notarizedDoc: NotarizedDocData}, true>(functions, 'updateNotarizedDoc');
// export const userChangeBuilderId = httpsCallable<{builderId: string}, true>(functions, 'userChangeBuilderId');

// export const updateMedicalInfo = httpsCallable<{builderId: string, physicalInfo: MedicalInfo.Physical.Information, mentalInfo: MedicalInfo.Mental.Information, medicationInfo: MedicalInfo.Medications.Information}, string>(functions, 'updateMedicalInfo');

// export const sendDoctorPDF = httpsCallable<{builderId: string, pdfURL: string}, true>(functions, 'sendDoctorPDF');

// export const adminGetInfo = httpsCallable<{email: string}, {uid: string, builderId: string | undefined}>(functions, 'adminGetInfo');

export default {};