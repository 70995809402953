import {Lockdown} from "@busy-human/vue-component-library";
import { APP_ROOT_EL } from "../app";
import { firestore } from "./firebase";
import { doc, getDoc, onSnapshot} from "firebase/firestore";
import { App } from "vue";

const APP_VERSION_DOC_PATH = "metadata/root";

export function installLockdownGate(app: App) {
    try {
        const docRef = doc(firestore, APP_VERSION_DOC_PATH);

        app.use(Lockdown, {
            getDoc: async () => {
                const snap = await getDoc(docRef);
                return snap.data();
            },
            onSnapshot(cb) {
                return onSnapshot(docRef, (snap) => {
                    cb(snap.data());
                });
            },
            lockdownField: "lockdown"
        });
    } catch(err:any) {
        console.warn("Erorr installing version manager");
        console.error(err);
    }
}
