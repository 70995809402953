import { DataInstance } from "@busy-human/gearbox";
import { Season } from "@busy-human/hxp-library";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { useAuth } from "./auth";

export const useSeasons = defineStore('seasons', () => {
    const seasons = ref<Map<string, DataInstance<Season.Model>>>(new Map());
    const ready = ref(false);
    const auth = useAuth();

    const override = ref<string | null>(null);
    const current = computed(() => (override.value 
        ? (seasons.value.get(override.value) || null) 
        : ([...seasons.value.values()].find(s => s.isCurrent) || null)
    ));


    const waitingPromises = ref<(() => void)[]>([]);

    function waitForReady() {
        if(ready.value) return Promise.resolve();
        else return new Promise<void>(res => {waitingPromises.value.push(res);});
    };

    async function refresh() {
        ready.value = false;
        seasons.value.clear();
        const res = (await Season.Collection.fetchAll()).dataItems();
        for(const s of res) {
            seasons.value.set(s.$id, s);
        }

        ready.value = true;
        waitingPromises.value.forEach(r => r());
        waitingPromises.value.length = 0;
    }

    function setSeasonOverride(seasonId: string) {
        override.value = seasonId;
    }

    function clearSeasonOverride() {
        override.value = null;
    }

    async function getSeasons(){
        await auth.waitForReady();
        if(!auth.isAdmin) throw new Error('User is not admin');
        return seasons.value;
    }

    refresh();

    return { current, waitForReady, refresh, setSeasonOverride, clearSeasonOverride, getSeasons };
});