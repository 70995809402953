
import { AssignedChecklistItem, BuilderRecord, Builders, ChecklistDefinitions, ChecklistItems, DataInstance, Destinations, Trips } from '@busy-human/hxp-library';
import { cloneDeep } from 'lodash';


// const ChecklistDefinition: ChecklistDefinitions.Model = {
//     name: 'Payment ',
//     description: '',

//     completionMethod: "payment-complete",
//     category: ChecklistDefinitions.ChecklistCategory.payment,
//     season: '', //This collection will likely become a subcollection of BuilderRecord so the season will already be referenced
//     personApplicability: ['all'],
//     tripApplicability: ['all'],
//     dueDateType: 'relative',
//     dueDateRelative: '60 days before departure'
// };

const passportChecklistDefinition: ChecklistDefinitions.Model = {
    name: 'Submit your passport',
    description: 'Submit your passport',

    completionMethod: "passport-submitted",
    category: ChecklistDefinitions.ChecklistCategory.passport,
    season: '', //This collection will likely become a subcollection of BuilderRecord so the season will already be referenced
    personApplicability: ['all'],
    tripApplicability: ['all'],
    dueDateType: 'relative',
    dueDateRelative: '60 days before departure',
    externalResource1: {url: 'www.google.com', name: 'US Passport Website'},
    modifiedTimestamp: null,
};

const builderPacketChecklistDefinition: ChecklistDefinitions.Model = {
    name: 'Builder Packet',
    description: 'Complete the Builder Packet',

    completionMethod: "builder-packet",
    category: ChecklistDefinitions.ChecklistCategory.builderPacket,
    season: '', //This collection will likely become a subcollection of BuilderRecord so the season will already be referenced
    personApplicability: ['teenBuilder'],
    tripApplicability: ['all'],
    dueDateType: 'relative',
    dueDateRelative: '60 days before departure',
    modifiedTimestamp: null,
};

const parentPacketChecklistDefinition: ChecklistDefinitions.Model = {
    name: 'Parent Builder Packet',
    description: 'Complete the Parent Builder Packet',

    completionMethod: "parent-builder-packet",
    category: ChecklistDefinitions.ChecklistCategory.parentBuilderPacket,
    season: '', //This collection will likely become a subcollection of BuilderRecord so the season will already be referenced
    personApplicability: [ 'parentBuilder'],
    tripApplicability: ['all'],
    dueDateType: 'relative',
    dueDateRelative: '60 days before departure',
    modifiedTimestamp: null,
};

const atHomePacketChecklistDefinition: ChecklistDefinitions.Model = {
    name: 'At Home Parent Packet',
    description: 'Complete the At Home Parent Packet',

    completionMethod: "at-home-packet",
    category: ChecklistDefinitions.ChecklistCategory.atHomePacket,
    season: '', //This collection will likely become a subcollection of BuilderRecord so the season will already be referenced
    personApplicability: ['all'],
    tripApplicability: ['all'],
    dueDateType: 'relative',
    dueDateRelative: '60 days before departure',
    modifiedTimestamp: null,
};

const parentGuardianInfoChecklistDefinition: ChecklistDefinitions.Model = {
    name: 'Parent Info',
    description: 'Submit your Parents Information',

    completionMethod: "parent-info-completed",
    category: ChecklistDefinitions.ChecklistCategory.parentInfo,
    season: '', //This collection will likely become a subcollection of BuilderRecord so the season will already be referenced
    personApplicability: ['teenBuilder'],
    tripApplicability: ['all'],
    dueDateType: 'relative',
    dueDateRelative: '90 days before departure',
    modifiedTimestamp: null,
};

const emergencyChecklistDefinition: ChecklistDefinitions.Model = {
    name: 'Emergency Contacts',
    description: 'Submit your Emergency Contacts',

    completionMethod: "emergency-contacts-submitted",
    category: ChecklistDefinitions.ChecklistCategory.emergency,
    season: '', //This collection will likely become a subcollection of BuilderRecord so the season will already be referenced
    personApplicability: ['all'],
    tripApplicability: ['all'],
    dueDateType: 'relative',
    dueDateRelative: '60 days before departure',
    modifiedTimestamp: null,
};

const allAboutYouChecklistDefinition: ChecklistDefinitions.Model = {
    name: 'All About You',
    description: 'Complete and Submit your information on the All About You page',

    completionMethod: "all-about-you-submitted",
    category: ChecklistDefinitions.ChecklistCategory.allAboutYou,
    season: '', //This collection will likely become a subcollection of BuilderRecord so the season will already be referenced
    personApplicability: ['all'],
    tripApplicability: ['all'],
    dueDateType: 'relative',
    dueDateRelative: '300 days before departure',
    modifiedTimestamp: null,
};

const notarizedAuthChecklistDefinition: ChecklistDefinitions.Model = {
    name: 'Notarized Authorization',
    description: 'Complete and Submit the Notarized Authorization Document',

    completionMethod: "notarized-authorization-submitted",
    category: ChecklistDefinitions.ChecklistCategory.notarizedAuthorizations,
    season: '', //This collection will likely become a subcollection of BuilderRecord so the season will already be referenced
    personApplicability: ['all'],
    tripApplicability: ['all'],
    dueDateType: 'relative',
    dueDateRelative: '60 days before departure',
    modifiedTimestamp: null,
};

const healthFormChecklistDefinition: ChecklistDefinitions.Model = {
    name: 'Health Form',
    description: 'Submit your Health Form Information',

    completionMethod: "health-form-submitted",
    category: ChecklistDefinitions.ChecklistCategory.healthForm,
    season: '', //This collection will likely become a subcollection of BuilderRecord so the season will already be referenced
    personApplicability: ['all'],
    tripApplicability: ['all'],
    dueDateType: 'relative',
    dueDateRelative: '60 days before departure',
    modifiedTimestamp: null,
};

const groupMeChecklistDefinition: ChecklistDefinitions.Model = {
    name: 'Group Me',
    description: 'Join your trips Group Me',

    completionMethod: "group-me-joined",
    category: ChecklistDefinitions.ChecklistCategory.groupMe,
    season: '', //This collection will likely become a subcollection of BuilderRecord so the season will already be referenced
    personApplicability: ['all'],
    tripApplicability: ['all'],
    cantStartBefore: '90 days before departure',
    dueDateType: 'relative',
    dueDateRelative: '60 days before departure',
    modifiedTimestamp: null,
};

const flightChecklistDefinition: ChecklistDefinitions.Model = {
    name: 'Flight Info',
    description: 'Submit Flight Information',

    completionMethod: "airport-transportation-submitted",
    category: ChecklistDefinitions.ChecklistCategory.flight,
    season: '', //This collection will likely become a subcollection of BuilderRecord so the season will already be referenced
    personApplicability: ['all'],
    tripApplicability: ['all'],
    dueDateType: 'relative',
    dueDateRelative: '60 days before departure',
    modifiedTimestamp: null,
};

const vaccineChecklistDefinition: ChecklistDefinitions.Model = {
    name: 'Vaccine',
    description: 'Submit your Vaccination history',

    completionMethod: "vaccination-submitted",
    category: ChecklistDefinitions.ChecklistCategory.vaccination,
    season: '', //This collection will likely become a subcollection of BuilderRecord so the season will already be referenced
    personApplicability: ['all'],
    tripApplicability: ['vaccineRequired'],
    dueDateType: 'relative',
    dueDateRelative: '60 days before departure',
    modifiedTimestamp: null,
};

const vaccineOptionalChecklistDefinition: ChecklistDefinitions.Model = {
    name: 'Vaccine(Optional)',
    description: 'View the Vaccine information for the trip',

    completionMethod: "user-checks-off",
    category: ChecklistDefinitions.ChecklistCategory.vaccination,
    season: '', //This collection will likely become a subcollection of BuilderRecord so the season will already be referenced
    personApplicability: ['all'],
    tripApplicability: ["unspecified", "domestic","international","visaRequired","affidavitRequired"],
    dueDateType: 'relative',
    dueDateRelative: '60 days before departure',
    modifiedTimestamp: null,
};

const visaChecklistDefinition: ChecklistDefinitions.Model = {
    name: 'Visa',
    description: 'Submit your Visa',

    completionMethod: "visa-submitted",
    category: ChecklistDefinitions.ChecklistCategory.visa,
    season: '', //This collection will likely become a subcollection of BuilderRecord so the season will already be referenced
    personApplicability: ['all'],
    tripApplicability: [ 'visaRequired'],
    cantStartBefore: '90 days before departure',
    dueDateType: 'relative',
    dueDateRelative: '60 days before departure',
    modifiedTimestamp: null,
};

const affidavitChecklistDefinition: ChecklistDefinitions.Model = {
    name: 'Affidavit',
    description: 'Submit your Affidavit',

    completionMethod: "affidavit-submitted",
    category: ChecklistDefinitions.ChecklistCategory.visa,
    season: '', //This collection will likely become a subcollection of BuilderRecord so the season will already be referenced
    personApplicability: ['all'],
    tripApplicability: ['affidavitRequired'],
    cantStartBefore: '90 days before departure',
    dueDateType: 'relative',
    dueDateRelative: '60 days before departure',
    modifiedTimestamp: null,
};

const parentBuilderInfoCheckDefinition: ChecklistDefinitions.Model = {
    name: 'Parent Builder Info',
    description: 'View the Parent Builder Info',

    completionMethod: "user-checks-off",
    category: ChecklistDefinitions.ChecklistCategory.parentBuilderInfo,
    season: '', //This collection will likely become a subcollection of BuilderRecord so the season will already be referenced
    personApplicability: [ 'parentBuilder'],
    tripApplicability: ['all'],
    dueDateType: 'relative',
    dueDateRelative: '60 days before departure',
    modifiedTimestamp: null,
};

const parentBackgroundCheckDefinition: ChecklistDefinitions.Model = {
    name: 'Parent Background Check',
    description: 'Complete your background check',

    completionMethod: "parent-background-check",
    category: ChecklistDefinitions.ChecklistCategory.parentBackgroundCheck,
    season: '', //This collection will likely become a subcollection of BuilderRecord so the season will already be referenced
    personApplicability: [ 'parentBuilder'],
    tripApplicability: ['all'],
    dueDateType: 'relative',
    dueDateRelative: '60 days before departure',
    modifiedTimestamp: null,
};

const parentTrainingDefinition: ChecklistDefinitions.Model = {
    name: 'Parent Training',
    description: 'Complete your training',

    completionMethod: "parent-training",
    category: ChecklistDefinitions.ChecklistCategory.parentTraining,
    season: '', //This collection will likely become a subcollection of BuilderRecord so the season will already be referenced
    personApplicability: [ 'parentBuilder'],
    tripApplicability: ['all'],
    dueDateType: 'relative',
    dueDateRelative: '60 days before departure',
    modifiedTimestamp: null,
};

const readWikipediaChecklistDefinition: ChecklistDefinitions.Model = {
    name: 'Read the Wikipedia Article',
    description: 'Read the Wikipedia Article about your trips destination to learn more about where you\'ll be staying',

    completionMethod: "user-checks-off",
    category: ChecklistDefinitions.ChecklistCategory.other,
    season: '', //This collection will likely become a subcollection of BuilderRecord so the season will already be referenced
    personApplicability: ['all'],
    tripApplicability: ['international'],
    dueDateType: 'relative',
    dueDateRelative: '30 days after registration',
    modifiedTimestamp: null,
};

const checklistDefinitionItems: ChecklistDefinitions.Model[] = [
    passportChecklistDefinition,
    builderPacketChecklistDefinition,
    parentPacketChecklistDefinition,
    atHomePacketChecklistDefinition,
    parentGuardianInfoChecklistDefinition,
    emergencyChecklistDefinition,
    allAboutYouChecklistDefinition,
    notarizedAuthChecklistDefinition,
    healthFormChecklistDefinition,
    groupMeChecklistDefinition,
    flightChecklistDefinition,
    vaccineChecklistDefinition,
    vaccineOptionalChecklistDefinition,
    affidavitChecklistDefinition,
    visaChecklistDefinition,
    parentBuilderInfoCheckDefinition,
    parentBackgroundCheckDefinition,
    parentTrainingDefinition,
    readWikipediaChecklistDefinition
];

function getBuilderApplicability(builderInfo: Builders.Model){
    switch(builderInfo.builderType){
        case 'parentBuilder':
        case 'tripLeader':
        case 'localCoordinator':
            return 'parentBuilder';
        case 'atHomeParent':
            return 'atHomeParent';
        default:
            return 'teenBuilder';
    }
}

function resolveTripApplicability(slot: Trips.Model, destination: Destinations.Model){
    const applicability: ChecklistDefinitions.ChecklistTripGroup[] = [];
    if(destination.isInternational){
        applicability.push('international');
    }else{
        applicability.push('domestic');
    }
    if(destination.visaIsRequired) applicability.push('visaRequired');
    //Data Type doesn't exist currently on destinations
    // if(destination.affidavitRequired) applicability.push('affidavitRequired');
    if(destination.vaccineRequired) applicability.push('vaccineRequired');
    return applicability;
}

function resolveDueDate(builder: BuilderRecord.Model, slot: Trips.Model, relativeDate: ChecklistDefinitions.RelativeDueDate){
    const relativeSplit = relativeDate.split(" ");
    const amount = parseInt(relativeSplit[0]) * (relativeSplit[2] === 'before' ? -1 : 1);
    const dateValue = relativeSplit[3] === 'registration' ? builder.registeredDate : slot.startDate;

    if(!dateValue) return null;

    const baseDate = new Date(dateValue);

    return new Date(baseDate.getFullYear(), baseDate.getMonth(), baseDate.getDate() + amount).toISOString();
}

export function getAssignedChecklistItemsForBuilder(builderInfo: DataInstance<Builders.Model>, builderRecord: BuilderRecord.Model, slot: Trips.Model, destination: Destinations.Model) {
    const checklistItems: AssignedChecklistItem.Model[] = [];

    if(!builderInfo || !builderRecord || !slot || !destination){
        return checklistItems;
    }

    const personApplicability = getBuilderApplicability(builderInfo);
    const tripApplicability = resolveTripApplicability(slot, destination);

    checklistDefinitionItems.forEach((checklistItem) => {

        let validTrip = true;
        if(!(checklistItem.personApplicability.includes('all') || checklistItem.personApplicability.includes(personApplicability))){
            validTrip = false;
        }
        if(!(checklistItem.tripApplicability.includes('all') || (checklistItem.tripApplicability.filter((applicability) => tripApplicability.includes(applicability)).length > 0))){
            validTrip = false;
        }

        if(validTrip){
            const checklistItemCopy: Partial<ChecklistDefinitions.Model> = cloneDeep(checklistItem);
            delete checklistItemCopy.personApplicability;
            delete checklistItemCopy.tripApplicability;
            delete checklistItemCopy.season;

            const resolvedDueDate = checklistItemCopy.dueDateType === 'relative' ? resolveDueDate(builderRecord, slot, checklistItemCopy.dueDateRelative!) : checklistItemCopy.dueDateAbsolute!;

            if(resolvedDueDate){
                const assignedItem: AssignedChecklistItem.Model = {
                    ...checklistItemCopy as Omit< ChecklistDefinitions.Model, "personApplicability" | "tripApplicability" | "season">,
                    status: "not-started",
                    resolvedAbsoluteDueDate: "" /* resolvedDueDate */,
                    builderId: builderInfo.$id
                };

                // if(assignedItem.cantStartBefore){
                //     const resolvedCantStartBefore = resolveDueDate(builderRecord, slot, assignedItem.cantStartBefore);
                //     // eslint-disable-next-line max-depth
                //     if(resolvedCantStartBefore) assignedItem.resolvedCantStartBeforeDate = resolvedCantStartBefore;
                // }

                checklistItems.push(assignedItem);
            }
        }
    });

    return checklistItems;
}