import { DataInstance, ZohoStrings } from "@busy-human/hxp-library";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useZohoStrings = defineStore('strings', () => {
    const _strings = ref<Record<string, DataInstance<ZohoStrings.Model>>>({});
    const strings = computed(() => _strings.value);

    const loaded = ref(false);
    const waitingPromises = ref<(() => void)[]>([]);


    // we can fetch all the available trips here
    ZohoStrings.Collection.fetchAll().then(dataStrings => {
        const tmp: typeof _strings['value'] = {};
        for(const string of dataStrings.dataItems()) {
            tmp[string.name] = string;
        }
        _strings.value = tmp;

        loaded.value = true;
        waitingPromises.value.forEach(res => res());
        waitingPromises.value.length = 0;
    });


    function waitForReady() {
        if(!loaded.value) return new Promise<void>(res => {waitingPromises.value.push(res);});
        else return Promise.resolve();
    }

    // const asArray = computed(() => Object.keys(_strings.value).map(slotId => _strings.value[slotId]));

    function getString(strName: string) {
        const res = strings.value[strName];
        if(!res) return strName;
        else return res.rendered || res.text;
    }

    return { strings, waitForReady, getString };
});