import { defineStore } from "pinia";
import { ref, watch } from "vue";

export const useTitle = defineStore('title', () => {
    const title = ref("Humanitarian Experience");
    const prefix = ref("HXP");
    
    watch(title, t => {
        document.title = `${prefix.value} | ${t}`;
    });

    return {title};
});