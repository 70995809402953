import { vModelDynamic as _vModelDynamic, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "password-input-container" }
const _hoisted_2 = ["placeholder", "type"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      class: "password-input",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.local) = $event)),
      placeholder: $props.placeholder,
      type: $setup.inputType
    }, null, 8, _hoisted_2), [
      [_vModelDynamic, $setup.local]
    ]),
    ($props.allowShow)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "password-show-password",
          onClick: $setup.toggleVisibility
        }, [
          _createElementVNode("img", { src: $setup.visibleIcon }, null, 8, _hoisted_3)
        ]))
      : _createCommentVNode("", true)
  ]))
}