/* eslint-disable max-lines */
/* eslint-disable no-use-before-define */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-depth */
import { DocumentEntity, type DataInstance, type SubsetEntity } from "@busy-human/gearbox";
import { Builders, BuilderRecord, Season } from "@busy-human/hxp-library";
import { defineStore } from "pinia";
import { ref, computed,watch } from "vue";
import { useAuth } from "./auth";
import { events,EventTypes } from "@/events";
import { useSeasons } from "./seasons";
import { cloneDeep } from 'lodash';
import { generateGoodEnoughPassword } from "@/util/passgen";
import { builderSetTrainingDate, createSubUser } from "@/util/functions";
import { getDoc } from "firebase/firestore";
import { useSeasonFeature } from "@/composables/useSeasonFeature";


export const useBuilderStore = defineStore('builders', () => {
    const LS_KEY = "hxp.current-builder";

    type builderGroup = {
        teen: string
        parent?: string
    }

    const auth = useAuth();
    const season = useSeasons();
    const checklistEnabled = useSeasonFeature(Season.Features.CHECKLIST);

    const builders = ref<{[id: string]: DataInstance<Builders.Model>}>({});
    const builderRecords = ref<Record<string, DataInstance<BuilderRecord.Model>>>({});

    const currentBuilderId = ref<keyof typeof builders['value'] | null>(null);

    const currentBuilder = computed(() => (currentBuilderId.value ? builders.value[currentBuilderId.value] : null));
    const currentBuilderRecord = computed(() => (currentBuilderId.value ? builderRecords.value[currentBuilderId.value]: null));
    const assignedTrip = computed(()=> (currentBuilderRecord.value?.tripAssigned ? currentBuilderRecord.value?.tripAssigned : null));

    function recordByBuilderId(builderId:string){
        return builderRecords.value[builderId];
    }

    const userBuilderNotFound = ref(false);

    const asArray = computed<typeof builders['value'][string][]>(() => Object.keys(builders.value).map(id => builders.value[id]));
    const withRecordAsArray = computed(() => asArray.value.filter((b) => !!builderRecords.value?.[b.$id]));
    const recordsAsArray = computed<typeof builderRecords['value'][string][]>(() => Object.keys(builderRecords.value).map(id => builderRecords.value[id]));

    const waitingResolves: (() => void)[] = [];
    const isReady = ref(false);

    const builderSubset = ref<null | SubsetEntity<Builders.Model>>(null);
    const builderRecordEntities = ref<Record<string, DocumentEntity<BuilderRecord.Model>>>({});
    function setupListeners(uid: string) {
        builderSubset.value = Builders.Collection.getBuildersForUserSubset(uid);
        builderSubset.value.onUpdate(() => {
            if(!builderSubset.value) return;
            for(const b of builderSubset.value.dataItems()) {
                if(b.isDeleted) {
                    if(b.$id in builders.value) delete builders.value[b.$id];
                } else {
                    builders.value[b.$id] = b;
                }
                const seasonId = season.current?.$id;
                if(!seasonId) return;
                if(!(b.$id in builderRecordEntities.value)) {
                    const ent = Season.Collection.subCollectionEntity(seasonId, "BuilderRecord")
                        .docEntity(b.$id);
                    ent.onUpdate(() => {
                        if(ent.data().season && ent.data().season === seasonId) builderRecords.value[ent.$id] = ent.data();
                        // else delete builderRecords.value[ent.$id];
                    });
                    ent.listen();
                    builderRecordEntities.value[ent.$id] = ent;
                }
            }
        });
        builderSubset.value.listen();
    }
    function cleanupListeners() {
        for(const ent of Object.values(builderRecordEntities.value)) {
            ent.cleanup();
        }
        builderRecordEntities.value = {};
        builderSubset.value?.cleanup();
        builderSubset.value = null;
    }

    watch(() => auth.currentUID, async uid => {
        await season.waitForReady();

        if(uid) {
            await refreshData();
            setupListeners(uid);
            readLocalStorage();
        } else {
            builders.value = {};
            builderRecords.value = {};
            cleanupListeners();
            clearLocalStorage();
        }
        isReady.value = true;
        waitingResolves.forEach(res => res());
        waitingResolves.length = 0;

        if(auth.isAdmin) {
            // eslint-disable-next-line func-names
            (window as any).trackBuilder = function(builderId: string) {
                manuallyTrackBuilder(builderId);
            };
        } else if((window as any).trackBuilder) {delete (window as any).trackBuilder;}
    }, { immediate: true });


    const waitForReady = () => {
        if(isReady.value) {return Promise.resolve();}
        else {
            return new Promise<void>(res => {waitingResolves.push(res);});
        }
    };

    async function getExistingRecordForSeason(id: string, seasonId: string):  Promise<DataInstance<BuilderRecord.Model> | undefined>{
        const docRef = Season.Collection.subCollectionEntity(seasonId, "BuilderRecord").docRef(id);
        const snap = await getDoc(docRef);
        if(snap.exists()){
            return {$id: id, ...snap.data()} as DataInstance<BuilderRecord.Model>;
        }else{
            return undefined;
        }
    };

    // eslint-disable-next-line complexity
    async function refreshData(seasonId?: string) {
        console.log("Builder store refreshing data");
        if(!auth.currentUID) return console.warn("Builder refresh called without being logged in");
        seasonId ??= season.current?.$id;
        if(!seasonId) throw new Error("Season not ready");
        const resolvedSeasonId = seasonId;
        const docs = await Builders.Collection.getBuildersForUser(auth.currentUID);
        const tmp: typeof builders['value'] = {};
        const tmp2: typeof builderRecords.value = {};

        for(const doc of docs) {
            if(!doc.isDeleted) {
                tmp[doc.$id] = doc;
            }
        }

        const recs = (await Promise.all(docs.filter(d => !d.isDeleted).map(d => getExistingRecordForSeason(d.$id, resolvedSeasonId)))).filter((rec) => !!rec) as DataInstance<BuilderRecord.Model>[];
        // console.log("Test", recs);

        // const recs = (await Promise.all(docs.filter(d => !d.isDeleted).map(d => Season.Collection.subCollectionEntity(resolvedSeasonId, "BuilderRecord").fetchData(d.$id))));
        for(const rec of recs) {
            tmp2[rec.$id] = rec as DataInstance<BuilderRecord.Model>;
        }

        const authstore = useAuth();
        // console.log(authstore.claims);
        if(authstore.claims && typeof authstore.claims.builderId === 'string' && typeof tmp[authstore.claims.builderId] === 'undefined' ) {
            try {
                const userbuilder = await Builders.Collection.fetchData(authstore.claims.builderId);
                tmp[userbuilder.$id] = userbuilder;

                try {
                    const userBuilderRecord = await Season.Collection.subCollectionEntity(resolvedSeasonId, 'BuilderRecord').fetchData(authstore.claims.builderId);
                    tmp2[userbuilder.$id] = userBuilderRecord;

                    if(userBuilderRecord.builderTypeDetails.pairUser && typeof tmp[userBuilderRecord.builderTypeDetails.pairUser] === 'undefined') {
                        const parentbuilder = await Builders.Collection.fetchData(userBuilderRecord.builderTypeDetails.pairUser);
                        tmp[parentbuilder.$id] = parentbuilder;
                    }
                } catch (e) {
                    console.error(e);
                }

                userBuilderNotFound.value = false;
            } catch (e) {
                console.error(e);
                if(Object.keys(tmp).length > 0) {
                    userBuilderNotFound.value = true;
                } else {
                    console.error("No builders??");
                }
            }
        }

        // eslint-disable-next-line require-atomic-updates
        builders.value = tmp;
        // eslint-disable-next-line require-atomic-updates
        builderRecords.value = tmp2;

        if(!currentBuilderId.value && Object.keys(builders.value).length > 0) {
            if(auth.subUserBuilderId) currentBuilderId.value = auth.subUserBuilderId;
            else currentBuilderId.value = withRecordAsArray.value?.[0]?.$id;
        };
        console.log("Builder store refreshed");
    }

    watch(() => season.current?.$id, async (seasonId, old) => {
        if(seasonId && old && (seasonId !== old)) {
            await refreshData(seasonId);
            if(withRecordAsArray.value.findIndex((b) => b.$id === currentBuilderId.value)){
                currentBuilderId.value = withRecordAsArray.value?.[0]?.$id || null;
            }
        }
    });

    async function getBuildersFromIds(builderIds: string[]): Promise<DataInstance<Builders.Model>[]> {
        const tmp: DataInstance<Builders.Model>[] = [];

        const promises: Promise<any>[] = [];
        builderIds.forEach(builderId => {
            promises.push(Builders.Collection.fetchDoc(builderId).then((builderRef) => {
                tmp.push({
                    ...builderRef.data(),
                    $id: builderId
                });
            }));
        });

        await Promise.allSettled(promises);

        return tmp;
    }

    async function addParents(parentGuardianOne: Builders.GuardianInfo | null, parentGuardianTwo: Builders.GuardianInfo | null){
        for(const key in builderRecords.value){
            const tmp = cloneDeep(builders.value[key]);
            if(tmp.builderType !== 'parentBuilder'){
                tmp.guardianInfo[0] = parentGuardianOne;
                tmp.guardianInfo[1] = parentGuardianTwo;
                await updateBuilder(tmp);
                await updateParentInfoStatus(tmp.$id, "complete");
            }else{
                await updateParentInfoStatus(tmp.$id, "complete");
            }
        }
    }

    async function setFirstExperience(firstExperience: boolean){
        for(const key in builderRecords.value){
            await updateFirstExperience(key, firstExperience);
        }
    }

    //async function updateCustodyAgreement(recordId:string, hasSoleCustody:boolean){
        //console.log("Called" , recordId , hasSoleCustody)
        //for(const key in builderRecords.value){
        //    const tmp = cloneDeep(builderRecords.value[key]);
        //    tmp.hasSoleCustody = hasSoleCustody;
        //    await updateBuilderRecord(tmp);
        //}  
    //}
    async function updateCustodyAgreement(recordId:string, hasCustodyAgreement:boolean) {
        for(const key in builderRecords.value){
            const tmp = cloneDeep(builderRecords.value[key]);
            tmp.hasCustodyAgreement = hasCustodyAgreement;
            await updateBuilderRecord(tmp);
        }
    }

    async function saveEmergencyContacts(contactOne: Builders.EmergencyContact, contactTwo:Builders.EmergencyContact){
        const tmp = cloneDeep(currentBuilder.value);
        if(tmp){
            tmp.emergencyContacts[0] = contactOne;
            tmp.emergencyContacts[1] = contactTwo;
            await updateBuilder(tmp);
        }
    }

   async function updateParentInfoStatus(recordId: string, status: BuilderRecord.Status){
        const tmp = cloneDeep(builderRecords.value[recordId]);
        tmp.guardianStatus = status;
        await updateBuilderRecord(tmp);
    }

    async function updateContactInfoStatus(recordId:string, status: BuilderRecord.Status){
        const tmp = cloneDeep(builderRecords.value[recordId]);
        tmp.contactInfoStatus = status;
        await updateBuilderRecord(tmp);
    }

    async function updateFirstExperience(recordId: string, firstTime: boolean){
        const tmp = cloneDeep(builderRecords.value[recordId]);
        tmp.firstExperience = firstTime;
        await updateBuilderRecord(tmp);
    }

    async function updatePrefStatus(recordId:string, status: BuilderRecord.Status){
        const tmp = cloneDeep(builderRecords.value[recordId]);
        tmp.prefStatus = status;
        await updateBuilderRecord(tmp);
    }

    async function updatePolicyStatus(recordId:string, status: BuilderRecord.Status){
        const tmp = cloneDeep(builderRecords.value[recordId]);
        tmp.policyStatus = status;
        await updateBuilderRecord(tmp);
    }

    async function updatePolicyFieldStatus(recordId:string, status: BuilderRecord.Status, policyField: keyof BuilderRecord.Model['policies']){
        const tmp = cloneDeep(builderRecords.value[recordId]);
        const currentTime = new Date();
        tmp.policies[policyField].status = status;
        if(status === "complete"){
            tmp.policies[policyField].completedTimestamp = currentTime.toISOString();
        }
        await updateBuilderRecord(tmp);

    }

    async function updateUserPassport(recordId: string, passport: BuilderRecord.PassportInformation){
        if(checklistEnabled.value !== Season.FeatureState.ENABLED) throw new Error('Updating Checklist Items has been disabled for this season');
        const tmp = cloneDeep(builderRecords.value[recordId]);
        tmp.passportInfo = passport;
        await updateBuilderRecord(tmp);
    }

    async function updateUserVisa(recordId: string, visa: BuilderRecord.DocumentStatus){
        if(checklistEnabled.value !== Season.FeatureState.ENABLED) throw new Error('Updating Checklist Items has been disabled for this season');
        const tmp = cloneDeep(builderRecords.value[recordId]);
        tmp.userVisa = visa;
        await updateBuilderRecord(tmp);
    }

    async function updateUserAffidavit(recordId: string, affidavit: BuilderRecord.DocumentStatus){
        if(checklistEnabled.value !== Season.FeatureState.ENABLED) throw new Error('Updating Checklist Items has been disabled for this season');
        const tmp = cloneDeep(builderRecords.value[recordId]);
        tmp.userAffidavit = affidavit;
        await updateBuilderRecord(tmp);
    }

    async function updateUserInsurance(recordId: string, insuranceCard: BuilderRecord.DocumentStatus){
        if(checklistEnabled.value !== Season.FeatureState.ENABLED) throw new Error('Updating Checklist Items has been disabled for this season');
        const tmp = cloneDeep(builderRecords.value[recordId]);
        tmp.insuranceCard = insuranceCard;
        await updateBuilderRecord(tmp);
    }

    async function updateUserImmunization(recordId: string, immunization: BuilderRecord.DocumentStatus){
        if(checklistEnabled.value !== Season.FeatureState.ENABLED) throw new Error('Updating Checklist Items has been disabled for this season');
        const tmp = cloneDeep(builderRecords.value[recordId]);
        tmp.immunizationRecord = immunization;
        await updateBuilderRecord(tmp);
    }

    async function updateUserHealthForm(recordId: string, healthForm: BuilderRecord.DocumentStatus){
        if(checklistEnabled.value !== Season.FeatureState.ENABLED) throw new Error('Updating Checklist Items has been disabled for this season');
        const tmp = cloneDeep(builderRecords.value[recordId]);
        tmp.healthForm = healthForm;
        await updateBuilderRecord(tmp);
    }

    async function updateUserPicture(recordId: string, picture: BuilderRecord.DocumentStatus){
        if(checklistEnabled.value !== Season.FeatureState.ENABLED) throw new Error('Updating Checklist Items has been disabled for this season');
        const tmp = cloneDeep(builderRecords.value[recordId]);
        tmp.userPicture = picture;
        await updateBuilderRecord(tmp);
    }

    async function updateAboutMe(recordId: string, aboutMe: BuilderRecord.BuilderAboutYouQuestions | BuilderRecord.ParentAboutYouQuestions){
        if(checklistEnabled.value !== Season.FeatureState.ENABLED) throw new Error('Updating Checklist Items has been disabled for this season');
        const tmp = cloneDeep(builderRecords.value[recordId]);
        tmp.aboutBuilderInfo = aboutMe;
        await updateBuilderRecord(tmp);
    }

    async function updateFlightInfo(recordId: string, flightInfo: BuilderRecord.BuilderFlightInfo ){
        if(checklistEnabled.value !== Season.FeatureState.ENABLED) throw new Error('Updating Checklist Items has been disabled for this season');
        const tmp = cloneDeep(builderRecords.value[recordId]);
        tmp.flightInfo = flightInfo;
        await updateBuilderRecord(tmp);
    }

    async function updateGroupMe(recordId: string, joinedGroupme: boolean ){
        if(checklistEnabled.value !== Season.FeatureState.ENABLED) throw new Error('Updating Checklist Items has been disabled for this season');
        const tmp = cloneDeep(builderRecords.value[recordId]);
        tmp.groupMeJoined = joinedGroupme;
        await updateBuilderRecord(tmp);
    }

    async function updateVidsWatched(recordId: string, watchedVids: boolean ){
        if(checklistEnabled.value !== Season.FeatureState.ENABLED) throw new Error('Updating Checklist Items has been disabled for this season');
        const tmp = cloneDeep(builderRecords.value[recordId]);
        tmp.watchedPrepVideos = watchedVids;
        await updateBuilderRecord(tmp);
    }

    async function updateTrainingDate(recordId: string, status: BuilderRecord.Model['parentTrainingSelected'],  trainingDate?: string ){
        if(checklistEnabled.value !== Season.FeatureState.ENABLED) throw new Error('Updating Checklist Items has been disabled for this season');
        const tmp = cloneDeep(builderRecords.value[recordId]);
        tmp.parentTrainingSelected = status;
        await updateBuilderRecord(tmp);
        if(trainingDate){
            await builderSetTrainingDate({
                builderId: recordId,
                seasonId: season.current?.$id || "",
                date: trainingDate
            });
        }
    }

    // This pattern we have going sucks. 
    async function updateBuilderFlightViewedDate(recordId: string) {
        const tmp = cloneDeep(builderRecords.value[recordId]);
        tmp.flightInfoLastViewed = new Date().toISOString();
        await updateBuilderRecord(tmp);
    }

    async function completeBuilderPacket(recordId: string){
        if(checklistEnabled.value !== Season.FeatureState.ENABLED) throw new Error('Updating Checklist Items has been disabled for this season');
        const tmp = cloneDeep(builderRecords.value[recordId]);
        tmp.builderPacket = {
            completedTimestamp: new Date().toISOString(),
            status: "complete"
        };
        await updateBuilderRecord(tmp);
    }

    async function createDefaultHealthInformation(recordId:string){
        const tmp = cloneDeep(builderRecords.value[recordId]);
        tmp.healthInformation = {
            sectionA:{
                completed: null,
                completedTimestamp: null
            },
            sectionB:{
                completed: null,
                completedTimestamp: null
            },
            sectionC: {
                doctorsName: null,
                doctorsEmail: null
            },
            HIPPAA: {
                completed: null,
                completedTimestamp: null,
            },

            HIPPAB: {
                completed: null,
                completedTimestamp: null,
                consentForRelease: null,
                relationshipToPatient: null
            },
            healthFormCreated: false
        };
        await updateBuilderRecord(tmp);
    }


    async function completeBuilderHealthSection(recordId: string, section:string){
        if(checklistEnabled.value !== Season.FeatureState.ENABLED) throw new Error('Updating Checklist Items has been disabled for this season');
        let tmp = cloneDeep(builderRecords.value[recordId]);
        if(tmp.healthInformation === undefined){
            await createDefaultHealthInformation(recordId);
            tmp = cloneDeep(builderRecords.value[recordId]);
        }

        if(tmp.healthInformation){
            if(section === 'sectionA' && !tmp.healthInformation.sectionA?.completed){
                tmp.healthInformation.sectionA = {
                    completed: true,
                    completedTimestamp: new Date().toISOString()         
                };
    
            }
    
            else if(section === 'sectionB' && !tmp.healthInformation.sectionB?.completed){
                tmp.healthInformation.sectionB = {
                    completed: true,
                    completedTimestamp: new Date().toISOString()         
                };
            }
    
            else if(section === 'HIPPAA' && !tmp.healthInformation.HIPPAA?.completed){
                tmp.healthInformation.HIPPAA = {
                    completed: true,
                    completedTimestamp: new Date().toISOString()   
                };
            }
    
            else if(section === 'HealthForm'){
                tmp.healthInformation.healthFormCreated = true;
            }
            await updateBuilderRecord(tmp);
        }
    }

    async function completeConsentRelease(recordId:string, consent:boolean, relationShip:BuilderRecord.relationshipToPatient ){
        if(checklistEnabled.value !== Season.FeatureState.ENABLED) throw new Error('Updating Checklist Items has been disabled for this season');
        const tmp = cloneDeep(builderRecords.value[recordId]);
        if(tmp.healthInformation){
            tmp.healthInformation.HIPPAB = {
                completed: true,
                completedTimestamp: new Date().toISOString(),
                consentForRelease: consent,
                relationshipToPatient: relationShip
            };
        }
        await updateBuilderRecord(tmp);
    }


    async function completedAtHomePacket(recordId: string){
        if(checklistEnabled.value !== Season.FeatureState.ENABLED) throw new Error('Updating Checklist Items has been disabled for this season');
        for(const key in builderRecords.value){
            const tmp = cloneDeep(builderRecords.value[key]);
            tmp.parentAtHomePacket = {
                completedTimestamp: new Date().toISOString(),
                status: "complete"
            };
            await updateBuilderRecord(tmp);
        }

    }

    async function updateDepositFields(recordId:string, pending: boolean, depositPaid: boolean){
        let updatedBuilder = false;
        const tmp = cloneDeep(builderRecords.value[recordId]);
        if(pending){
            if(tmp.depositPending !== pending){
                tmp.depositPending = true;
                updatedBuilder = true;
            }
        }

        if(depositPaid){
            if(tmp.depositPaid !== depositPaid){
                tmp.depositPaid = depositPaid;
                updatedBuilder = true;
            }

            if(tmp.depositPending){
                tmp.depositPending = false;
                updatedBuilder = true;
            }
        }

        if(updatedBuilder){
            await updateBuilderRecord(tmp);
        }
    }

    async function updateBuilder(builder: DataInstance<Builders.Model>) {
        const tmp: Builders.Model = { ... builder };
        delete (tmp as any).$id;
        await Builders.Collection.updateDoc(builder.$id, tmp);
        builders.value[builder.$id] = builder;
        events.emit(EventTypes.builderUpdated);
    }

    async function updateBuilderRecord(record: DataInstance<BuilderRecord.Model>) {
        const tmp: BuilderRecord.Model = { ... record };
        delete (tmp as any).$id;
        await Season.Collection.subCollectionEntity(season.current?.$id || "", "BuilderRecord").updateDoc(record.$id, tmp);
        builderRecords.value[record.$id] = record;
        events.emit(EventTypes.builderUpdated);
    }

    function selectBuilder(builderId: string): void
    function selectBuilder(builder: DataInstance<Builders.Model>): void
    function selectBuilder(inp: DataInstance<Builders.Model> | string) {
        if(typeof inp === 'string') {
            currentBuilderId.value = inp;
        } else {
            currentBuilderId.value = inp.$id;
        }

        saveLocalStorage();
        events.emit(EventTypes.builderChanged,currentBuilder.value);
    }

    function getParentBuilder(){
        for(const builder of asArray.value){
            if(builder.builderType === Builders.BuilderType.ParentBuilder || builder.builderType === Builders.BuilderType.AtHomeParent){
                return builder.$id;
            }
        }
    }

    const parentBuilders = computed(() => asArray.value.filter((builder: DataInstance<Builders.Model>) => builder.builderType === 'parentBuilder'));

    function readLocalStorage() {
        const tmp = localStorage.getItem(LS_KEY);
        if(tmp) {
            if(typeof tmp === 'string' && Object.keys(builders.value).includes(tmp) && Object.keys(builderRecords.value).includes(tmp)){
                selectBuilder(tmp);
            }
        }
    }

    async function manuallyTrackBuilder(builderId: string) {
        try {
            const b = await Builders.Collection.fetchDoc(builderId);
            builders.value[b.$id] = b.data();
            currentBuilderId.value = b.$id;
            console.log(`Builder ${builderId}:`, builders.value[builderId]);
            // const bookingStore = useBookings();
            // await bookingStore.addBookingsForBuilder(builderId);
        } catch(e) {console.error(e);}
    }

    function saveLocalStorage() {
        if(currentBuilderId.value && typeof currentBuilderId.value === 'string')
            {localStorage.setItem(LS_KEY, currentBuilderId.value);}
    }

    function clearLocalStorage() {
        localStorage.removeItem(LS_KEY);
    }

    const lockdownForm = computed(()=>{
        if(currentBuilderRecord.value && currentBuilderRecord.value.status === "assigned"){
            return true;
        }else{
            return false;
        }
    });

    function validateTeenBirthday(birthday: string | undefined){
        if(!birthday) return 'invalid';


        const test = new Date(birthday).getTime();
        if(isNaN(test)) return 'invalid';


        const builderAge = Builders.GetBuilderAge(birthday);

        if(builderAge < (Builders.MinimumAge-1) ) return 'tooYoung';

	    return 'valid';
    }

    //UPDATE TO USE FILTER?
    // eslint-disable-next-line max-lines
    const selectableBuilders = computed<typeof builders['value'][string][]>(()=>{
        const selectable:typeof builders['value'][string][] = [];

        asArray.value.forEach((builder)=>{
            if(builder && builder.birthday && validateTeenBirthday(builder.birthday) === 'valid'){
                selectable.push(builder);
            }
        });
        return selectable;
    });

    //UPDATE TO USE FILTER?
    const selectableRecords = computed<typeof builderRecords['value'][string][]>(()=>{
        const selectable:typeof builderRecords['value'][string][] = [];
        recordsAsArray.value.forEach((record)=>{
            const builder = builders.value[record.$id];
            if(builder && builder.birthday && validateTeenBirthday(builder.birthday) === 'valid'){
                selectable.push(record);
            }
        });
        return selectable;
    });

    const selectableBuildersForPayments = computed<typeof asArray.value>(()=>{
        const selectable:typeof asArray.value = [];

        asArray.value.forEach((builder)=>{
            const record = builderRecords.value[builder.$id];
            if(record && builder.birthday && validateTeenBirthday(builder.birthday) === 'valid' && record.zohoBuilderId !== undefined){
                selectable.push(builder);
            }
        });

        return selectable;
    });


    const selectableRecordsForPayment = computed<typeof recordsAsArray.value>(()=>{
        const selectable:typeof recordsAsArray.value = [];
        recordsAsArray.value.forEach((record)=>{
            const builder = builders.value[record.$id];
            if(builder && builder.birthday && validateTeenBirthday(builder.birthday) === 'valid' && record.zohoBuilderId !== undefined){
                selectable.push(record);
            }
        });
        return selectable;
    });



    const atleastOneBuilderHasPaidDeposit = computed(()=>{
        let hasBeenPaid = false;
        recordsAsArray.value.forEach((record)=>{
            if(record.depositPaid){
                hasBeenPaid = true;
            }
        });
        return hasBeenPaid;
    });

    /**
     * Creates a new sub-user for a builder. Will generate and return a random password.
     * Existing sub-users are not allowed to make more sub-users.
     * @param builderId 
     * @param email 
     * @returns Auto-generated password
     */
    async function createSubUserForBuilder(builderId: string, email: string) {
        const builder = builders.value[builderId];
        if(!auth.currentUID) throw new Error("Not logged in");
        if(!builder) throw new Error("Builder not found");
        if(builder.subUserId) throw new Error("Builder already has sub-user");
        if(auth.isSubUser) throw new Error("Sub users cannot create other sub-users");

        const password = generateGoodEnoughPassword();
        await createSubUser({
            // We need to pass in the currentUID here, because I just know admins
            // are going to come in and screw things up otherwise.
            builderId, email, password, userId: auth.currentUID
        });

        return password;
    }

    return { builders, builderRecords, currentBuilderRecord, recordByBuilderId,
            getBuildersFromIds, asArray, withRecordAsArray, currentBuilder, updateBuilder,
            updateBuilderRecord, selectBuilder, getParentBuilder,
            refreshData, userBuilderNotFound, isReady, waitForReady,
            manuallyTrackBuilder, updateContactInfoStatus,recordsAsArray,
            updatePrefStatus,parentBuilders, addParents, updatePolicyStatus,
            updatePolicyFieldStatus, assignedTrip, lockdownForm,
            updateUserVisa, 
            setFirstExperience, updateFirstExperience,
            updateUserAffidavit, 
            updateUserPicture, 
            updateUserInsurance,
            updateUserImmunization,
            updateUserHealthForm,
            updateGroupMe, updateAboutMe, updateVidsWatched,
            updateTrainingDate,
            updateBuilderFlightViewedDate,
            saveEmergencyContacts, completeBuilderPacket, completedAtHomePacket,
            updateDepositFields, updateUserPassport, selectableBuilders, selectableRecords,
            updateFlightInfo, atleastOneBuilderHasPaidDeposit, selectableBuildersForPayments,
            selectableRecordsForPayment, createSubUserForBuilder, updateCustodyAgreement,
            completeBuilderHealthSection , completeConsentRelease};
});