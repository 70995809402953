import "core-js/stable";
import "regenerator-runtime/runtime";

import {initEvents, FeatureFlags, AnnouncementManager, FirestoreAnnouncementSource, MockAnnouncementSource} from "@busy-human/vue-component-library";
import "@busy-human/vue-component-library/style";
FeatureFlags.setFlags(CONFIG.flags);
FeatureFlags.setFlagsForContext("beta", CONFIG.flags);

import "@busy-human/hxp-library/styles";
import "@busy-human/vue-component-library/shaft/shaft.scss";
import { installVersionManager } from "./util/version";
import "./stylesheets/app.scss";

import events from "./events";
initEvents(events);

import { createPinia } from 'pinia';
const pinia = createPinia();

import router from "./router";
import app, { APP_ROOT_EL } from "./app";




import Toast, { POSITION, type PluginOptions } from 'vue-toastification';
import "vue-toastification/dist/index.css";
const toastOptions: PluginOptions =  {
    'position': POSITION.BOTTOM_LEFT,
    'timeout': 4000,
};

app.use(pinia);
app.use(router);
app.use(Toast, toastOptions);

installVersionManager(app);
import { installLockdownGate } from "./util/lockdown";
installLockdownGate(app);
FeatureFlags.install(app, {
    env: ENVIRONMENT
});

const Announcements = new AnnouncementManager();
// Announcements.addSource(new MockAnnouncementSource());
import { firestore } from "./util/firebase";
if(FeatureFlags.isFeatureEnabled("ANNOUNCEMENTS_ENABLED")) {
    Announcements.addSource(new FirestoreAnnouncementSource(firestore));
    app.use(Announcements);
}


import { useAuth } from '@/store/auth';
import { useSeasons } from "./store/seasons";

useAuth(); // Starting the auth store early

useSeasons().waitForReady().then(() => {
    app.mount(APP_ROOT_EL);
});