import { createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createElementVNode as _createElementVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "#application-root" }, [
    _createElementVNode("div", {
      class: "auth-popup",
      onClick: _cache[6] || (_cache[6] = ($event: any) => ($setup.emit('close')))
    }, [
      _createVNode(_Transition, {
        name: "auth",
        mode: "out-in",
        appear: ""
      }, {
        default: _withCtx(() => [
          ($setup.mode === $setup.stages.signup)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "auth-modal",
                onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
              }, [
                _createVNode($setup["SignUp"], {
                  class: "signup-component",
                  onMoveToLogin: _cache[0] || (_cache[0] = ($event: any) => ($setup.mode = $setup.stages.login)),
                  onDone: _cache[1] || (_cache[1] = ($event: any) => ($setup.emit('close')))
                })
              ]))
            : ($setup.mode === $setup.stages.login)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "auth-modal",
                  onClick: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["stop"]))
                }, [
                  _createVNode($setup["Login"], {
                    class: "login-component",
                    onMoveToSignup: _cache[3] || (_cache[3] = ($event: any) => ($setup.mode = $setup.stages.signup)),
                    onDone: _cache[4] || (_cache[4] = ($event: any) => ($setup.emit('close')))
                  })
                ]))
              : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ])
  ]))
}