import { firestore } from '@/util/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';

export type zohoNavItem = {
	text: string
	url?: string,
	section: 'destinations' | 'registration' | 'whoCanGo' | 'about' | 'answers' | 'insideHXP' | 'getAnswers' | 'legalDisclaimer' | 'contactUs',
	subSection?: string
	order?: number
	region: 'header' | 'footer'
}


export async function getZohoNavLinks(){
    const zohoCollection = collection(firestore, "ZohoNavLinks");

    const links: zohoNavItem[] = [];
    const q = query(zohoCollection, where("region", "==", "footer"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        links.push(doc.data() as zohoNavItem);
    });

	return links;
}
