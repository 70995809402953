// totally used chatgpt to make this list lol
const words = [
    "maple",
    "breeze",
    "harbor",
    "echo",
    "comet",
    "beacon",
    "meadow",
    "crystal",
    "harmony",
    "oasis",
    "radiant",
    "jubilee",
    "ponder",
    "velvet",
    "serene",
    "quiver",
    "zenith",
    "cascade",
    "celestial",
    "odyssey"
];

export function generateGoodEnoughPassword() {
    const i = Math.floor(Math.random() * words.length);
    const word = words[i].toLowerCase();
    const capitalized = `${word[0].toUpperCase()}${word.slice(1)}`;
    const numbs = `${Math.floor(Math.random() * 1000)}`.padStart(3, "0");
    return `${capitalized}${numbs}`;
}