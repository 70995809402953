import {initEvents, FeatureFlags, AppVersionManager, useProjectMetadata} from "@busy-human/vue-component-library";
import { APP_ROOT_EL } from "../app";
import { firestore } from "./firebase";
import { doc, getDoc, onSnapshot} from "firebase/firestore";
import { App } from "vue";

const APP_VERSION_DOC_PATH = "metadata/root";
const { applicationName } = useProjectMetadata();

applicationName.value = "HXP Builder Portal";

export function installVersionManager(app: App) {
    try {
        const docRef = doc(firestore, APP_VERSION_DOC_PATH);

        app.use(AppVersionManager, {
            getDoc: async () => {
                const snap = await getDoc(docRef);
                return snap.data();
            },
            onSnapshot(cb) {
                return onSnapshot(docRef, (snap) => {
                    cb(snap.data());
                });
            },
            localVersion: VERSION
        });
    } catch(err:any) {
        console.warn("Erorr installing version manager");
        console.error(err);
    }
}
