import mitt from "mitt";

type emitterType = {
    "selectedRoute": string[],
    "closeAgenda": void,
    "builderChanged": any,
    "refreshData": void,
    "builderUpdated": void,
    "closeNav": void,
    "openPolicy": string
    "selectedPolicy": string,
    "openWelcomeBack": string | void,
    "hideWelcomeBack": boolean,
    "startRegistration": void
}
const emitter = mitt<emitterType>();
export enum EventTypes{
    builderChanged = "builderChanged",
    builderUpdated = "builderUpdated",
    refreshData = "refreshData",
    closeNav = "closeNav",
    closeAgenda = "closeAgenda",
    editBuilder = "editBuilder",
    addBuilder = "addBuilder",
    startRegistration = "startRegistration",
    openPolicy = "openPolicy",
    selectedPolicy = "selectedPolicy",
    openWelcomeBack = "openWelcomeBack",
    hideWelcomeBack = "hideWelcomeBack"

}
export {emitter as events};
export default emitter;